
import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import { Link } from 'react-router-dom';
import { FaComments } from 'react-icons/fa'
import Slider from "react-slick";

const { Title, Paragraph, Text } = Typography;

const data = [
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/10.jpg', title: "Make Healthy Choices To Stay Looking Fine" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/5.jpg', title: "Give Your Body & Mind The Proper Nutrients" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/12.jpg', title: "Good Nutrition Makes A Healthy Body And Mind" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/4.jpg', title: "A Healthy Diet Is A Solution Of Our Health Problems" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/2.jpg', title: "Don't Give Up On Your Favorite Meal, Make Healthy" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/7.jpg', title: "Tips For Staying Fit And Healthy On Vacations" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/9.jpg', title: "Ways To Maintain Health Blood Sugar" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/3.jpg', title: "The Power Of Simple Lifestyle Changes" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/11.jpg', title: "Being Happy Is The Best Way To Grow Your Health" },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/6.jpg', title: "How Much Do Eat Health Food You Really Need Day?" },
]


let settings = {
    dots: true,
    autoplay: true,
    speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 1,
    responsive: [
        { breakpoint: 991, settings: { slidesToShow: 2 } },
        { breakpoint: 767, settings: { slidesToShow: 1 } }
    ]
}

export default function News() {

    // const navigate = useNavigate();

    return (
        <>
            <div className="container py-6">

                <Title level={4} className='text-success text-center'>OUR NEWS</Title>
                <Title level={1} className='text-center mt-0'>Featured News And Advice</Title>

                <Row gutter={[16, 36]} className='mt-5'>
                    <Col span={24}>

                        <Slider {...settings} arrows={false}>

                            {data.map((item, i) => <Row gutter={16} key={i}>
                                <Col span={24}>
                                    <div className="bg-secondary" style={{ minHeight: 485 }}>
                                        <Image src={item.url} preview={false} />
                                        <div className="p-2 p-md-4">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <Text className="bg-success d-inline-block p-2 text-white fw-bold">30 AUG</Text>
                                                <Text className='d-block fs-6'><FaComments className='text-success me-2 mb-1' size={18} /><span>0</span> Comments</Text>
                                            </div>
                                            <Title className='' level={5}>{item.title}</Title>
                                            <Paragraph ellipsis={{ rows: 2 }} className='fs-6 mb-3'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</Paragraph>
                                            <Link className='text-success fw-bold' to='#'>READ MORE</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>)}

                        </Slider>

                    </Col>
                </Row>
            </div>
        </>
    )
}
