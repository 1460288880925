import React from 'react'
import Services from './Services'
import Hero from './Hero'
import Prices from '../Home/Prices'
import News from 'components/frontend/News'

export default function index() {
    return (
        <>
            <Hero />
            <Services />
            <Prices />
            <News />
        </>
    )
}