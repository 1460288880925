import React, { useState } from 'react'
import { Form, Input, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from 'config/firebase';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Title, Text } = Typography

const initialState = { email: "", password: "", }

export default function Login() {

  const { dispatch } = useAuthGlobalContext()

  const [state, setState] = useState(initialState)
  const [isProcessing, setIsProcessing] = useState(false)
  let navigate = useNavigate()


  const handleChange = e => {
    let { name, value } = e.target
    setState(s => ({ ...s, [name]: value }))
  }


  const handleLogin = () => {

    let { email, password } = state;
    if (!window.isEmail(email)) { return window.toastify("Please enter a valid email address", "error") }
    if (password.length < 6) { return window.toastify("Enter password minimum 6 characters", "error") }

    setIsProcessing(true)

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in 
        let user = userCredential.user;
        readUserData(user)
        // ...
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          window.toastify('This Email is Not Rsgister.', "error");
        } else {
          window.toastify("Something went wrong. Please try again or contact support team.", "error")
        }
        setIsProcessing(false)
      })
  }

  const readUserData = async (authUser) => {
    const docRef = doc(firestore, "users", authUser.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let user = docSnap.data()
      const isSuperAdmin = user.roles.includes("superAdmin")
      // const isAdmin = user.roles.includes("admin")
      const isCustomer = user.roles.includes("customer")
      dispatch({ type: "LOGIN", payload: { user, isSuperAdmin ,isCustomer} })
      navigate(-1)
      window.toastify("Login successful", "success")

    } else {
      console.log("No such document!");
    }
    setIsProcessing(false)
  }

  return (
    <div className='bg-auth flex-center'>
      <div className="p-4 auth-card" style={{ minWidth: 350 }}>
        <Title className='m-0 text-center text-primary fw-bold'>LOGIN</Title>
        <Form layout='vertical' className='py-4'>
          <Form.Item>
            <Input placeholder='Enter Your Email' name='email' className='bg-whie border-0 rounded-0 p-3' onChange={handleChange} />
          </Form.Item>
          <Form.Item>
            <Input.Password placeholder="Enter Your Password" name='password' className='bg-whie border-0 rounded-0 p-3' onChange={handleChange} />
          </Form.Item>
          <Link to='#' className='text-primary fs-6 fw-bold d-block text-end'>Forget Password</Link>
          <button className='btn-dark mt-3 d-block mx-auto w-75' onClick={handleLogin} >
            {isProcessing
              ? "LOADING..."
              : 'LOGIN'
            }
          </button>
          <div className="flex-center pt-3">
            <Text className='m-0 d-block text-primary  fw-bold fs-6'>Create an Account</Text>
            <Link to='/auth/register' className='ms-2 d-block fs-6'>Register</Link>
          </div>
        </Form>
      </div>
    </div>
  )
}
