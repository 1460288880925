import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Breadcrumb, Col, Form, Input, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Title } = Typography
const initialState = { title: "", fullName: "", phone: "", address: "", city: "", code: "", description: "" };

export default function Profile() {

    const { user } = useAuthGlobalContext()

    const [state, setState] = useState(initialState);
    const [file, setFile] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false);
    const inputRef = useRef(null);

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        multiple: false,
        onDrop: acceptedFiles => { setFile(acceptedFiles[0]) }
    });

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const readUser = useCallback(async () => {

        const docRef = doc(firestore, "users", user.uid)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
            let data = docSnap.data();
            setState(data)
        } else {
            window.toastify("Something went wrong", "error")
        }
    }, [user.uid])

    useEffect(() => {
        readUser()
    }, [readUser])

    const handleUpdate = () => {

        let { title, fullName, phone, address, city, code, description } = state;

        if (!title) { return window.toastify("Please enter professional title", "error") }
        if (!fullName) { return window.toastify("Please enter full name", "error") }
        if (!phone) { return window.toastify("Please enter phone", "error") }
        if (!address) { return window.toastify("Please enter address", "error") }
        if (!city) { return window.toastify("Please enter city", "error") }
        if (!code) { return window.toastify("Please enter zip code", "error") }
        if (!description) { return window.toastify("Please enter description", "error") }
        if (!file) { return window.toastify("Please upload file", "error") }

        const formData = {
            title, fullName, phone, address, city, code, description,
            id: window.getRandomId()
        }
        setIsProcessing(true)
        uploadFile(formData)
    }

    const uploadFile = (formData) => {

        const ext = file.name.split('.').pop()
        const pathwithFileName = `${formData.id}/images/photo.${ext}`

        const fileRef = ref(storage, pathwithFileName);

        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on("state_changed", (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
            (error) => {
                console.error(error)
                window.toastify("Something went wrong while uploading photo.", "error")
                setIsProcessing(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let photo = { url: downloadURL, size: file.size }
                    createDocument({ ...formData, photo })

                });
            }
        )
    }

    const createDocument = async (formData) => {

        const docRef = doc(firestore, "users", user.uid)
        setIsProcessing(true)
        try {
            await updateDoc(docRef, formData)
            window.toastify("A user profile successfully updated", "success")
            setIsProcessing(false)
        } catch (error) {
            console.log(error)
            setIsProcessing(false)
            window.toastify("Something went wrong. Please try again", "error")
        }

    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Dashboard - Settings - Profile</Title>
                </Col>
            </Row>
            <div className='mb-4'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link className='text-decoration-none' to="/dashboard">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Profile</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <div className="card  mb-3">
                        <div className="card-body py-4">
                            <div>
                                <div className='d-flex justify-content-center'>
                                    <div className="img-border" onClick={handleUpload} >
                                        {
                                            !state.photo ?
                                                <img src={!file ? ("https://res.cloudinary.com/dufkxmegs/image/upload/v1679915607/avatar_vxqxen.png") : URL.createObjectURL(file)} className='user-img img-fluid ' alt='user-img' />
                                                :
                                                <img src={user.photo?.url} alt="user-img" className='user-img img-fluid' />
                                        }
                                        <div {...getRootProps({ className: 'dropzone' })} style={{ display: "none" }}>
                                            <input {...getInputProps()} ref={inputRef} />
                                            <p>Click or drag file</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mt-2'>
                                    <h5 className='p-0 m-0 '>{state.fullName}</h5>
                                    <p className='p-0 mt-2'>{state.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                    <div className="card p-4">
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="Name" required>
                                        <Input placeholder="Name" name="fullName" value={state.fullName} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="Professional title" required>
                                        <Input placeholder="Professional Title" name="title" value={state.title} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="Email" required>
                                        <Input placeholder="Email" disabled name="email" value={state.email} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="Phone number" required>
                                        <Input placeholder="Phone Number" name="phone" value={state.phone} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="City" required>
                                        <Input placeholder="City" name="city" value={state.city} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="Zip/Code" required>
                                        <Input placeholder="Zip Code" name="code" value={state.code} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="Address" required>
                                        <Input placeholder="Address" name="address" value={state.address} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Form.Item label="Short Bio">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Short Bio (Max Length: 100 Characters)" name="description" maxLength={100} showCount value={state.description} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-end'>
                                <button className='btn btn-primary text-white' onClick={handleUpdate} disabled={isProcessing} style={{ width: "130px" }}>
                                    {
                                        !isProcessing ? "Save Changes" : <span className='spinner-border spinner-border-sm'></span>
                                    }
                                </button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )
}
