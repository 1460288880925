import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Add from './Add'

export default function index() {
    return (
        <Routes>
            <Route index element={<Add />} />
        </Routes>
    )
}
