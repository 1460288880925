import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png'
import { useAuthGlobalContext } from 'context/AuthContext'

export default function Navbar() {

  const { isAuthenticated, user, handlLogOut } = useAuthGlobalContext()

  const [showMenu, setShowMenu] = useState(false);

  const handleToggle = () => setShowMenu(!showMenu);

  const handleLinkClick = () => {
    // Hide the dropdown menu on medium screens only
    if (window.innerWidth < 992) {
      setShowMenu(false);
    }
  };

  return (
    <header className='position-sticky top-0' style={{ zIndex: 100 }}>
      <nav className="navbar navbar-expand-lg bg-secondary">
        <div className="container">
          <Link className="navbar-brand p-0" to="/"><img src={logo} className='logoClass' alt="logo" />Dietetics Refill</Link>
          <button className="navbar-toggler" type="button" onClick={handleToggle}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${showMenu ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active fw-bold fs-6" to="/" onClick={handleLinkClick}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold fs-6" to="/about" onClick={handleLinkClick}>About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold fs-6" aria-current="page" to="/contact" onClick={handleLinkClick}>Contact</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold fs-6" aria-current="page" to="/services" onClick={handleLinkClick}>Our Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold fs-6" aria-current="page" to="/appointment" onClick={handleLinkClick}>Appointment</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold fs-6" aria-current="page" to="/blog" onClick={handleLinkClick}>Blog</Link>
              </li>
              <li className="nav-item">
                {isAuthenticated
                  ? <>
                    {
                      user?.roles[0] === 'superAdmin'
                        ? <Link className="nav-link active fw-bold fs-6" aria-current="page" to="/dashboard" onClick={handleLinkClick}>Dashboard</Link>
                        : <button className="nav-link active fw-bold fs-6" aria-current="page" onClick={() => { handleLinkClick(); handlLogOut() }}>Logout</button>
                    }
                    {/* <Link className="nav-link active fw-bold fs-6" aria-current="page" to="/dashboard" onClick={handleLinkClick}>Dashboard</Link> */}
                  </>
                  : <Link className="nav-link active fw-bold fs-6" aria-current="page" to="/auth/login" onClick={handleLinkClick}>Login</Link>
                }

              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header >
  );
};