import { useState, useEffect, useCallback } from 'react';
import { firestore } from 'config/firebase';
import { collection, doc, query, updateDoc, where, limit, getDocs } from 'firebase/firestore';
import { Breadcrumb, Button, Table, Tag, Input } from 'antd';
import { Link } from 'react-router-dom';

const { Search } = Input;

export default function Messages() {

    const [searchText, setSearchText] = useState('');
    const [messages, setMessages] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handleSearch = value => {
        setSearchText(value);
    };

    const readDocs = useCallback(async () => {

        const collectionRef = collection(firestore, "messages");
        const statusActive = where("status", "==", "active");
        const q = query(collectionRef, statusActive, limit(pageSize));

        setIsLoading(true);
        const querySnapshot = await getDocs(q);
        const array = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const dateCreated = doc.data().dateCreated;
            let date = null;
            if (dateCreated && dateCreated.seconds) {
                date = new Date(dateCreated.seconds * 1000 + dateCreated.nanoseconds / 1000000);
                data.date = date.toLocaleString();
            }
            data.key = doc.id;
            array.push(data);
        });
        array.sort((a, b) => b.dateCreated.seconds - a.dateCreated.seconds);
        setMessages(array);
        setFilteredDocuments(array);
        setIsLoading(false);

    }, [pageSize]);

    useEffect(() => {
        readDocs();
    }, [readDocs]);

    useEffect(() => {
        const start = (currentPage - 1) * pageSize;
        const end = start + pageSize;
        setFilteredDocuments(messages.slice(start, end));
    }, [currentPage, pageSize, messages]);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    useEffect(() => {
        const filteredData = messages?.filter((item) =>
            item.fullName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredDocuments(filteredData);
    }, [messages, searchText]);

    const handleReadDoc = async (id) => {
        const docRef = doc(firestore, "messages", id);
        try {
            await updateDoc(docRef, { isunRead: false })
        } catch (error) {
            console.log(error)
            window.toastify("Something went wrong.", "error")
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'fullName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (tags) => (<> <Tag color="green">  {tags} </Tag> </>)
        },

    ];

    const rowClassName = (record, index) => {
        if (record.isunRead) {
            return "rowColor"
        }
    };

    return (
        <div className='content-min-vh'>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/dashboard" className='text-decoration-none'>Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Contact Us</Breadcrumb.Item>
            </Breadcrumb>
            <div className="py-4">
                <h5>Contact Us</h5>
            </div>
            <div className="bg-white p-4">
                <div className='py-4 d-flex justify-content-between align-items-center'>
                    <div className='d-flex '>
                        <p className='fw-bold pt-1 w-50'>Contact Us</p>
                        <Search placeholder='Search' onChange={e => handleSearch(e.target.value)} />
                    </div>
                    <div>
                        <Button type='primary' disabled >Delete</Button>
                    </div>
                </div>

                <Table loading={isLoading}
                    dataSource={filteredDocuments}
                    columns={columns}
                    pagination={{ current: currentPage, pageSize: pageSize, total: messages.length, showSizeChanger: true, }}
                    rowClassName={rowClassName}
                    onChange={handleTableChange}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div className='px-5'>
                                <div className="d-flex align-items-center">
                                    <p className='fw-bold me-3'>Subject :  </p>
                                    <p>{record.subject} </p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className='fw-bold me-3'>Messages :</p>
                                    <p>{record.message} </p>
                                </div>
                            </div>
                        ),
                        rowExpandable: (record) => record.name !== 'Not Expandable',
                        onExpand: (expanded, record) => {
                            if (record.isunRead === false) {
                                return record;
                            } else {
                                return handleReadDoc(record.id);
                            }
                        }
                    }}
                    scroll={{ x: true }}
                    bordered
                />
            </div>
        </div>
    )
}
