import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import icon1 from '../../../assets/images/nutrition (1).png'
import icon2 from '../../../assets/images/healthy-food.png'
import icon3 from '../../../assets/images/nutrition.png'
import icon4 from '../../../assets/images/bowl.png'
import { Link } from 'react-router-dom'

const { Title, Text } = Typography;

const data = [
    { icon: icon1, title: 'Child Nutrition' },
    { icon: icon2, title: 'Personal Coaching' },
    { icon: icon3, title: 'Sports Nutritionist' },
    { icon: icon4, title: 'Fitness Performance' },
]

export default function Expertise() {

    return (

        <div className="bg-secondary py-5">
            <div className="container">

                <Title level={4} className='text-success text-center'>WHY CHOOSE US</Title>
                <Title level={1} className='text-center mt-0'>Our Main Areas Of Expertise</Title>

                <Row gutter={[16, 16]} className='mt-5'>

                    {data.map((item, i) => <Col xs={24} md={12} xl={6} key={i}>
                        <div className="bg-white p-4 rounded-3 card-shahdow">
                            <Image src={item.icon} width={50} height={50} preview={false} />
                            <Title className='mt-3' level={4}>{item.title}</Title>
                            <Text className='fs-6 d-block mb-2'>It is a long established fact that a reader will distracted the readable content</Text>
                            <Link className='text-success fw-bold' to='#'>READ MORE</Link>
                        </div>
                    </Col>)}

                </Row>

            </div>
        </div>

    )
}
