import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import icon1 from '../../../assets/images/icon1.png'
import icon3 from '../../../assets/images/nutrition.png'
import icon4 from '../../../assets/images/bowl.png'
import icon5 from '../../../assets/images/si1.png'
import icon6 from '../../../assets/images/si2.png'
import icon7 from '../../../assets/images/icon2.png'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

const { Title, Text } = Typography;

const data = [
    { icon: icon1, title: 'Weight Loss Program' },
    { icon: icon3, title: 'Fitness Performance' },
    { icon: icon6, title: 'Personal Coaching' },
    { icon: icon4, title: 'Personalized Nutrition' },
    { icon: icon5, title: 'Balance Body & Mind' },
    { icon: icon7, title: 'Child Nutrition' },
]

const projects = [
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/3.jpg", title: 'Physical Activity' },
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/4.jpg", title: 'Childern diet' },
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/1.jpg", title: 'Weight losses sessions' },
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/6.jpg", title: 'Eat Healthy Food' },
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/2.jpg", title: 'Pregnancy Diet' },
]

let settings = {
    dots: true,
    autoplay: true,
    speed: 500,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
        { breakpoint: 991, settings: { slidesToShow: 2 } },
        { breakpoint: 767, settings: { slidesToShow: 1 } }
    ]
}

export default function Services() {
    return (
        <>
            <div className='container py-7'>

                <Title level={4} className='text-success text-center'>WHAT WE OFFER</Title>
                <Title level={1} className='text-center mt-0'>Our Diet &amp; Nutrition Services</Title>

                <Row gutter={[16, 36]} className='mt-5'>

                    {data.map((item, i) => <Col xs={24} md={12} xl={8} key={i}>
                        <div className="card main bg-secondary px-4 py-6 rounded-4">
                            <Image src={item.icon} width={50} height={50} preview={false} />
                            <Title className='mt-3' level={3}>{item.title}</Title>
                            <Text className='fs-6 d-block text-justify mb-3'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</Text>
                            <Link className='text-success fw-bold' to='#'>READ MORE</Link>
                        </div>
                    </Col>)}

                </Row>

            </div>

            <div className="bg-primary py-6 services-card">
                <Title level={4} className='text-success text-center'>EXPLORE OUR PROJECTS</Title>
                <Title level={1} className='text-center text-white mt-0 mb-5'>Our Recently Completed ProjectsServices</Title>

                <Row>
                    <Col span={24}>
                        <Slider {...settings} arrows={false}>

                            {projects.map((item, i) => <Row key={i} gutter={[16]}>
                                <Col span={24}  >
                                    <div className="">
                                        <Image src={item.url} preview={false} width='100%' height='100%' />
                                        <div className="bg-secondary p-2 mx-2 mb-3" style={{ marginTop: -100, position: 'sticky', zIndex: 10 }}>
                                            <Title level={5} className='text-success'>DIETS</Title>
                                            <Title className='mt-0' level={4}>{item.title}</Title>
                                        </div>
                                    </div>
                                </Col>
                            </Row>)}

                        </Slider>
                    </Col>
                </Row>

            </div>

        </>
    )
}
