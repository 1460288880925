import React from 'react'
import { Col, Image, Row, Typography, Progress } from 'antd'

const { Title, Text } = Typography

export default function Skills() {

    return (

        <div className="home-skill">
            <Row>

                <Col md={24} lg={24} xl={12} >
                    <div className="flex-center" style={{ minHeight: 600 }}>
                        <div className="p-5 ms-4">

                            <Title level={4} className='text-success'>NUTRITION SKILL</Title>
                            <Title level={1} className='mt-0'>We Give You Inspiration <br /> For A Healthy Life</Title>
                            <Text className='fs-6'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected</Text>\

                            <Title level={5} className='mb-0 mt-3'>Nutrition Strategies</Title>
                            <Progress percent={55} />
                            <Title level={5} className='mb-0 mt-3'>Weight Loss Program</Title>
                            <Progress percent={75} />
                            <Title level={5} className='mb-0 mt-3'>Motivation Nutrition</Title>
                            <Progress percent={89} />

                        </div>
                    </div>
                </Col>

                <Col md={24} lg={24} xl={12} >
                    <Image src='https://peacefulqode.com/themes/nutritius/html/images/skill/1.jpg' preview={false} />
                </Col>

            </Row>

        </div>

    )
}
