import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function NoPage() {

    const navigate = useNavigate()

    return (

        <div className='text-center py-7'>
            <h1 className='text-secondary fw-bold' style={{ fontSize: 150 }}>404!</h1>
            <h4 className='text-dark fw-bold'>404 Error</h4>
            <p>Oops! This Page is Not Found.</p>
            <button className='btn-dark mt-2' onClick={() => navigate('/')} >BACK HOME</button>
        </div>

    )
}
