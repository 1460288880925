import React from 'react'
import { Link, useLocation } from "react-router-dom"

export default function InsufficientPermission() {

    const location = useLocation()

    if (location.pathname.split("/")[1] === "dashboard")
        return (
            <main className='d-flex justify-content-center align-items-center'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <div className="card p-3 p-md-4 text-center">
                                <h4 className="mb-0 text-primary">You don't have permission to access this page.</h4>
                                <Link to={-1} className="btn btn-primary mt-4">Go Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )

    return (
        <main className='d-flex justify-content-center align-items-center bg-primary'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div className="card p-3 p-md-4 text-center">
                            <h2 className="mb-0 text-primary">You don't have permission to access this page.</h2>
                            <Link to={-1} className="btn btn-primary mt-4">Go Back</Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
