import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { AiFillHome } from 'react-icons/ai';
import { FaComments } from 'react-icons/fa'


const { Title,Text,Paragraph } = Typography;

export default function SingleBlog() {

  let navigate = useNavigate()

  return (
    <>
      <div className="blog-hero d-flex align-items-center">
        <div className="container">
          <Row>
            <Col span={24}>
              <Title className='text-primary mb-0 w-50'>Tips For Staying Fit And Healthy On Vacations</Title>
              <div className="d-flex align-items-center">
                <Title level={5} className='text-primary mt-3 d-flex align-items-center cursor-pointer' onClick={() => navigate('/')} ><AiFillHome color='#08220f' className='me-2' size={18} /> Home </Title>
                <Title level={5} className='text-success ms-2 mt-3 d-flex align-items-center cursor-pointer'><MdOutlineKeyboardArrowRight color='#08220f' className='me-2' size={20} /> Tips For Staying Fit And Healthy On Vacations </Title>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container py-6">
        <div className="card main card-hover">
          <Image src='https://nutritius.peacefulqode.com/wp-content/uploads/2022/08/7.jpg' className='card-img' preview={false} />
          <div className="p-2 p-md-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Text className="bg-success d-inline-block p-2 text-white fw-bold">30 AUG</Text>
              <Text className='d-block fs-6'><FaComments className='text-success me-2 mb-1' size={18} /><span>0</span> Comments</Text>
            </div>
            {/* <Title className='' level={4}>{item.title</Title>
            <Paragraph ellipsis={{ rows: 2 }} className='fs-6 mb-3'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</Paragraph>
            <Link className='text-success fw-bold' to='/blog/id:'>READ MORE</Link> */}
          </div>
        </div>
      </div>

    </>
  )
}
