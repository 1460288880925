import React, { useState } from 'react'
import { Form, Input, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { auth, firestore } from 'config/firebase';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Title, Text } = Typography

const initialState = {
  fullName: '',
  email: "",
  password: "",
  confirmPassword: "",
}


export default function Register() {

  const { dispatch } = useAuthGlobalContext()

  const [state, setState] = useState(initialState)
  const [isProcessing, setIsProcessing] = useState(false)
  let navigate = useNavigate()

  const handleChange = e => {
    let { name, value } = e.target
    setState(s => ({ ...s, [name]: value }))
  }


  const handleRegister = () => {

    let { email, password, confirmPassword, fullName } = state;

    if (fullName.length < 3) { return window.toastify("Please enter First Name", "error") }
    if (!window.isEmail(email)) { return window.toastify("Please enter a valid email address", "error") }
    if (password.length < 6) { return window.toastify("Enter password minimum 6 characters", "error") }
    if (!confirmPassword) { return window.toastify("Please enter comfirm password", "error") }
    if (confirmPassword !== password) { return window.toastify("Password doesn't match.", "error") }

    setIsProcessing(true)

    createUserWithEmailAndPassword(auth, email, password, confirmPassword)
      .then((userCredential) => {
        // Signed in 
        let user = userCredential.user;
        addDocument(user)
        // navigate('/')
        // ...
      })
      .catch((error) => {
        if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
          window.toastify('This is Email already exist.', "error");
        } else {
          window.toastify("Something went wrong. Please try again or contact support team.", "error")
        }
        setIsProcessing(false)
      })
  }

  const addDocument = async (userCredential) => {
    let { email, uid, displayName } = userCredential;

    let user = {
      fullName: state.fullName || displayName,
      email,
      uid,
      dateCreated: serverTimestamp(),
      status: "active",
      roles: ["customer"],
    }

    try {
      await setDoc(doc(firestore, "users", user.uid), user);
      dispatch({ type: "LOGIN", payload: { user, isCustomer: true } })
      window.toastify("A new user has been successfully registered.", "success")
      setIsProcessing(false)
      navigate(-2)
    } catch (error) {
      console.log('error', error)
      setIsProcessing(false)
      window.toastify("Something went wrong while creating user profile. Please try again or contact support team.", "error")
    }
  }




  return (
    <div className='bg-auth flex-center'>
      <div className="p-4 auth-card" style={{ minWidth: 350 }}>
        <Title className='m-0 text-center text-primary fw-bold'>REGISTER</Title>
        <Form layout='vertical' className='py-4'>

          <Form.Item>
            <Input placeholder='Full Name' name='fullName' className='bg-whie border-0 rounded-0 p-3' onChange={handleChange} />
          </Form.Item>

          <Form.Item>
            <Input placeholder='Enter Your Email' name='email' className='bg-whie border-0 rounded-0 p-3' onChange={handleChange} />
          </Form.Item>

          <Form.Item>
            <Input.Password placeholder="Enter Password" name='password' className='bg-whie border-0 rounded-0 p-3' onChange={handleChange} />
          </Form.Item>

          <Form.Item>
            <Input.Password placeholder="Enter Confrim Password" name='confirmPassword' className='bg-whie border-0 rounded-0 p-3' onChange={handleChange} />
          </Form.Item>

          <button className='btn-dark mt-3 d-block mx-auto w-75' onClick={handleRegister}>
            {isProcessing
              ? "LOADING..."
              : "REGISTER"
            }
          </button>

          <div className="flex-center pt-3">
            <Text className='m-0 d-block text-primary  fw-bold fs-6'>Already have account</Text>
            <Link to='/auth/login' className='ms-2 d-block fs-6'>Login</Link>
          </div>

        </Form>
      </div>
    </div>
  )
}
