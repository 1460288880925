import React from 'react'
import Appointments from './Appointments'

export default function index() {
    return (
        <>
            <Appointments />
        </>
    )
}
