import React, { useCallback, useState, useEffect } from 'react'
import { Col, Form, Input, Rate, Row, Typography } from 'antd'
import { useDropzone } from 'react-dropzone';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
// import { useAuthGlobalContext } from 'context/AuthContext';

const { Title } = Typography;
const initialState = { firstName: "", lastName: "", title: "", rating: 0, sortOrder: 1, description: "" }

export default function EditTestimonial() {

    // const { user } = useAuthGlobalContext();
    const [state, setState] = useState(initialState);
    const [photo, setPhoto] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const params = useParams();
    let navigate = useNavigate();

    const { getRootProps: getRootPhotoProps, getInputProps: getInputPhotoProps } = useDropzone({
        accept: { 'image/*': [] }, multiple: false,
        onDrop: acceptedFiles => { setPhoto(acceptedFiles[0]) }
    });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleRateChange = (val) => {
        setState(s => ({ ...s, rating: val }))
    }

    const readTestimonial = useCallback(async () => {

        const docRef = doc(firestore, "testimonial", params.id)
        setIsProcessing(true)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setState(data)
        } else {
            window.toastify("Testimonial not found", "error")
        }
        setIsProcessing(false)

    }, [params.id])

    useEffect(() => {
        readTestimonial()
    }, [readTestimonial])

    const handleUpdate = () => {
        let { firstName, lastName, title, rating, sortOrder, description } = state;

        firstName = firstName.trim();
        lastName = lastName.trim();
        let fullName = (firstName + " " + lastName).trim();
        title = title.trim();
        rating = Number(rating)
        sortOrder = Number(sortOrder);
        description = description.trim();

        if (firstName.length < 3) { return window.toastify("Please enter first name correctly", "error") }
        if (!title) { return window.toastify("Please enter professional title", "error") }
        if (!sortOrder) { return window.toastify("Please enter sort order", "error") }
        if (!rating) { return window.toastify("Please enter rating", "error") }
        if (!description) { return window.toastify("Please enter description", "error") }

        const formData = {
            firstName, lastName, fullName, title, rating, sortOrder, description,
            status: "active",
            dateModified: serverTimestamp(),
            // modifiedBy: {
            //     fullName: user.name,
            //     email: user.email,
            //     uid: user.uid
            // }
        }
        setIsProcessing(true)
        if (photo) {
            uploadFile(formData)
        } else {
            updateDocument(formData)
        }

    }

    const uploadFile = (formData) => {

        const ext = photo.name.split('.').pop()
        const pathwithFileName = `${state.id}/images/photo.${ext}`

        const fileRef = ref(storage, pathwithFileName);

        const uploadTask = uploadBytesResumable(fileRef, photo);

        uploadTask.on("state_changed", (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
            (error) => {
                console.error(error)
                window.toastify("Something went wrong while uploading photo.", "error")
                setIsProcessing(false)
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let image = {
                        url: downloadURL,
                        size: photo.size
                    }
                    formData.photo = image
                    updateDocument(formData)
                });
            }
        )
    }

    const updateDocument = async (formData) => {

        try {
            await updateDoc(doc(firestore, "testimonial", state.id), formData)
            navigate("/dashboard/website-settings/testimonials")
            window.toastify("Document has been successfully updated", "success")
        } catch (e) {
            console.error(e)
            window.toastify("Something went wrong while updating document", "error")
        }
        setIsProcessing(false)
    }
    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Testimonial</Title>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col xs={24} xl={16} >
                    <div className="card p-4 mb-3">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Edit Testimonial</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="First Name" required>
                                        <Input placeholder='First Name' name='firstName' value={state.firstName} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Last Name" required>
                                        <Input placeholder='Last Name' name='lastName' value={state.lastName} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Professional Title" required>
                                        <Input placeholder='Professional Title' name='title' value={state.title} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div className='mb-3'>
                                        <Title level={5} className="mb-0"> Star Rating</Title>
                                        <Rate className='fs-3' value={state.rating} onChange={handleRateChange} />
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={24}>
                                    <Form.Item label="Description">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Description" value={state.description} name="description" maxLength={200} showCount onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <Form layout="vertical">
                            <Form.Item label="Picture (640*640) px" className='mb-0 text-center'>
                                <>
                                    {photo
                                        ? <img src={URL.createObjectURL(photo)} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                        : <img src={state.photo?.url} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                    }

                                    <div {...getRootPhotoProps({ className: 'dropzone p-1 mt-2' })}>
                                        {/* <InboxOutlined /> */}
                                        <input name='photo' {...getInputPhotoProps()} />
                                        <p className='mb-0'>Click or drag file</p>
                                    </div>
                                </>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleUpdate}>
                            {!isProcessing
                                ? "Update"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}
