import React, { useState } from 'react'
import { Col, Form, Image, Input, Row, Select, DatePicker, Typography } from 'antd'
import { useNavigate } from 'react-router-dom';
import { useAuthGlobalContext } from 'context/AuthContext';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from 'config/firebase';

const { Title } = Typography;
const { TextArea } = Input;

const initialState = { departmentType: 'nutrition', patientName: '', email: '', phoneNumber: '', appointmentDate: '', message: '', }

export default function Appointment() {

    const { user } = useAuthGlobalContext()

    const [state, setState] = useState(initialState)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const handleType = (val) => {
        setState(s => ({ ...s, departmentType: val }))
    }

    const handleDate = (dateObj, val) => {
        setState(s => ({ ...s, appointmentDate: val }))
    }

    const handleChange = (e) => {
        setState(s => ({ ...s, [e.target.name]: e.target.value }))
    }

    const handleSubmit = () => {

        if (user.uid) {

            let { patientName, email, phoneNumber, message, departmentType, appointmentDate } = state;
            patientName = patientName.trim()
            message = message.trim()

            if (patientName.length < 3) { return window.toastify('Please Enter the Patient Name', "error"); }
            if (!window.isEmail(email)) { return window.toastify("Please enter a valid email address", "error") }
            if (message.length < 10) { return window.toastify('Please Enter your the message', "error"); }
            if (!departmentType) { return window.toastify('Please Enter your the Department Type', "error"); }
            if (!appointmentDate) { return window.toastify('Please Enter your the Appointment Date', "error"); }

            let formData = { patientName, email, phoneNumber, departmentType, appointmentDate, message, status: 'active', isunRead: true, sound: false }
            formData.dateCreated = serverTimestamp()
            formData.id = window.getRandomId()
            formData.createdBy = {
                email: user.email,
                uid: user.uid
            }

            setIsLoading(true)
            createDoc(formData);
        } else {
            navigate('/auth/login')
            window.toastify("First you need Login", "error")
        }
    }

    const createDoc = async (formData) => {
        try {
            await setDoc(doc(firestore, "appointments", formData.id), formData)
            setIsLoading(false)
            setState(initialState)
            window.toastify("Yours Appointment has been successfully Submit", "success")
        } catch (error) {
            window.toastify("Something went wrong while creating document.")
        }
    }

    return (
        <>
            <div className="container py-6 appointment-card">
                <Row gutter={[0, 16]}>

                    <Col md={24} lg={12} className='bg-secondary p-5 flex-center'>
                        <Form layout='vertical' >
                            <Row>
                                <Col span={24}>
                                    <Title level={1} className='mb-3'>Make Appointment</Title>
                                </Col>

                                <Col span={24}>
                                    <Form.Item label="Select Department" className='label'>
                                        <Select defaultValue='nutrition' onChange={handleType} value={state.departmentType}>
                                            <Select.Option value="nutrition">Nutrition</Select.Option>
                                            <Select.Option value="sports-nutrition">Sports Nutrition</Select.Option>
                                            <Select.Option value="deitetics">Deitetics</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item>
                                        <Input placeholder='Patient Name' name='patientName' value={state.patientName} className='p-3 bg-white border-1 rounded-0 border-secondary' onChange={handleChange} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Row gutter={[16, 16]}>

                                        <Col xs={24} md={12}>
                                            <Form.Item>
                                                <Input placeholder='Phone Number' name='phoneNumber' value={state.phoneNumber} className='p-3 bg-white border-1 rounded-0 border-secondary' onChange={handleChange} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <Form.Item>
                                                <Input placeholder='Email Address' name='email' value={state.email} className='p-3 bg-white border-1 rounded-0 border-secondary' onChange={handleChange} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col span={24}>
                                    <Row gutter={[16, 16]}>

                                        <Col xs={24} md={24}>
                                            <Form.Item>
                                                <DatePicker className='w-100 p-3' onChange={handleDate}
                                                    disabledDate={(current) => window.disabledDate(current, "past")} />
                                            </Form.Item>
                                        </Col>

                                        {/* <Col xs={24} md={12}>
                                            <Form.Item>
                                                <TimePicker className='w-100 p-3' onChange={handleTime} />
                                            </Form.Item>
                                        </Col> */}

                                    </Row>
                                </Col>

                                <Col span={24}>
                                    <TextArea placeholder='Write Your Message' name='message' value={state.message} rows={4} className='p-3 bg-white border-1 rounded-0 border-secondary' onChange={handleChange} />
                                    <button className='btn-light w-75 d-block mx-auto mt-5 fw-bold' onClick={handleSubmit} >
                                        {isLoading
                                            ? "LOADING..."
                                            : "BOOK APOINTMENTS"
                                        }
                                    </button>
                                </Col>

                            </Row>
                        </Form>
                    </Col>

                    <Col md={24} lg={12}>
                        <Image src='https://peacefulqode.com/themes/nutritius/html/images/protfolio/1.jpg' className='d-none d-lg-block' preview={false} width='100%' height='100%' />
                    </Col>

                </Row >
            </div >

            <div className="bg-primary py-6 appointment-card2">
                <div className="container">
                    <Row>
                        <Col xs={24} lg={16}>
                            <Title level={4} className='text-success '>HEALTY FOOD, FOR LUNCH</Title>
                            <Title level={1} className='m-0 text-white'>It Is Time To Balance Your Life With Natural Ingredients</Title>
                        </Col>
                        <Col xs={24} lg={8}>
                            <button className='btn-light w-75 d-block mx-auto mt-5 fw-bold' style={{ background: '#7fb82d', color: "black" }} onClick={() => navigate('/contact')} >CONTACT US</button>
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    )
}
