import React, { useState } from 'react'
import { Col, Divider, Form, Input, Row, Typography } from 'antd'
import { useDropzone } from 'react-dropzone';
import { doc, serverTimestamp, setDoc, } from 'firebase/firestore';
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import { useAuthGlobalContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const initialState = {
    title: "", name: "", sortOrder: 1, description: "",
    socialMedia: { facebook: "", twitter: "", instagram: "" }
}

export default function AddOurTeam() {

    // const { user } = useAuthGlobalContext()
    const [state, setState] = useState(initialState);
    const [file, setFile] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    let navigate = useNavigate();

    const { getRootProps: getRootPhotoProps, getInputProps: getInputPhotoProps } = useDropzone({
        accept: { 'image/*': [] }, multiple: false,
        onDrop: acceptedFiles => { setFile(acceptedFiles[0]) }
    });

    const handleChange = e => {

        const { name, value } = e.target
        setState(s => ({ ...s, [name]: value }))

    }
    const handleChangeSocialMedia = e => {

        const { name, value } = e.target
        setState(s => ({ ...s, socialMedia: { ...s.socialMedia, [name]: value } }))

    }

    const handleSubmit = () => {

        let { title, name, sortOrder, description, socialMedia } = state;

        title = title.trim();
        name = name.trim();
        sortOrder = Number(sortOrder);

        if (title.length < 3) { return window.toastify("Please enter professional title", "error") }
        if (name.length < 3) { return window.toastify("Please enter name", "error") }
        if (!description) { return window.toastify("Please enter description", "error") }
        if (!socialMedia.facebook) { return window.toastify("Please enter facebook url", "error") }
        if (!socialMedia.twitter) { return window.toastify("Please enter twitter url", "error") }
        if (!socialMedia.instagram) { return window.toastify("Please enter instagram url", "error") }
        if (!file) { return window.toastify("Please upload photo", "error") }
        if (file.size > 1048576) { return window.toastify("File size should be less than 1MB", "error") }

        const formData = {
            title, name, sortOrder, description, socialMedia,
            status: "active",
            dateCreated: serverTimestamp(),
            id: window.getRandomId(),
            // createdBy: {
            //     fullName: user.name,
            //     email: user.email,
            //     uid: user.uid
            // }
        }
        setIsProcessing(true)
        uploadFile(formData)

    }

    const uploadFile = (formData) => {

        const ext = file.name.split('.').pop()
        const pathwithFileName = `${formData.id}/images/photo.${ext}`

        const fileRef = ref(storage, pathwithFileName);

        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on("state_changed", (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
            (error) => {
                console.error(error)
                window.toastify("Something went wrong while uploading photo.", "error")
                setIsProcessing(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let photo = { url: downloadURL, size: file.size }
                    createDocument({ ...formData, photo })
                });
            }
        )
    }

    const createDocument = async (formData) => {

        const docRef = doc(firestore, "team", formData.id)
        try {
            await setDoc(docRef, formData, { merge: true })
            navigate("/dashboard/website-settings/ourteams")
            window.toastify("A new team member has been successfullfy added", "success")
        } catch (error) {
            console.log(error)
            window.toastify("Something went wrong. Please try again", "error")
        }
        setIsProcessing(false)

    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Add Team</Title>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col xs={24} xl={16} >
                    <div className="card p-4 mb-3">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Add Team</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Name" required>
                                        <Input placeholder='Name' name='name' onChange={handleChange} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Professional Tilte" required>
                                        <Input placeholder='Professional Title' name='title' onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input placeholder='Sort Order' min={1} value={state.sortOrder} name='sortOrder' onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={24}>
                                    <Form.Item label="Description">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Description" name="description" maxLength={200} showCount onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Divider orientation='left'><Title level={5}>Social Media</Title></Divider>
                                </Col>
                                <Col xs={24} sm={12} lg={6}>
                                    <Form.Item label="Facebook" required>
                                        <Input placeholder="Facebook complete URL" name="facebook" onChange={handleChangeSocialMedia} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={6}>
                                    <Form.Item label="Twitter" required>
                                        <Input placeholder="Twitter complete URL" name="twitter" onChange={handleChangeSocialMedia} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} lg={6}>
                                    <Form.Item label="Instagram" required>
                                        <Input placeholder="Instagram complete URL" name="instagram" onChange={handleChangeSocialMedia} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <Form layout="vertical">
                            <Form.Item label="Picture (640*640) px" className='mb-0 text-center'>
                                <>
                                    {file
                                        ? <img src={URL.createObjectURL(file)} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                        : <img src={state.photo?.url} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                    }

                                    <div {...getRootPhotoProps({ className: 'dropzone p-1 mt-2' })}>
                                        {/* <InboxOutlined /> */}
                                        <input name='photo' {...getInputPhotoProps()} />
                                        <p className='mb-0'>Click or drag file</p>
                                    </div>
                                </>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleSubmit}>
                            {!isProcessing
                                ? "Add"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}
