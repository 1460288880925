import React from 'react'

// import About from './About'
import CalculateMask from './CalculateMask'
import Hero from './Hero'
import Services from './Services'
import MoreInfo from './MoreInfo'
import Experties from './Expertise'
import Skills from './Skills'
import Prices from './Prices'
import Testimonial from './Testimonials'
// import Appointment from './Appointment'
import News from 'components/frontend/News'

export default function index() {
    return (
        <>
            <Hero />
            <MoreInfo />
            <Services />
            <CalculateMask />
            <Experties />
            <Skills />
            <Prices />
            <Testimonial />
            {/* <Appointment /> */}
            <News />
        </>
    )
}