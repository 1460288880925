import React, { useState } from 'react'
import { Breadcrumb, Col, Form, Input, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { auth } from 'config/firebase'
import { updatePassword } from "firebase/auth";
import { useAuthGlobalContext } from 'context/AuthContext'

const { Title } = Typography
const initialState = { newPassword: "", confirmPassword: "" }

export default function Account() {

    const { user } = useAuthGlobalContext();
    const [state, setState] = useState(initialState);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleChangePassword = async () => {

        let { newPassword, confirmPassword } = state
        if (newPassword.length < 8) { return window.toastify("Enter your new password / Minimum Length 8", "error") }
        if (confirmPassword !== newPassword) { return window.toastify("Password doesn't match.", "error") }
        const user = auth.currentUser;
        setIsProcessing(true)

        updatePassword(user, newPassword).then(() => {
            // Update successful.
            setState({ newPassword: "", confirmPassword: "" })
            window.toastify("Password successfully updated", "success")
            setIsProcessing(false)
        }).catch((error) => {
            // An error ocurred
            console.log(error)
            setIsProcessing(false)
            if (error.code === "auth/requires-recent-login") { return window.toastify("If you want change password. Please login again", "error") }
        });

        setIsProcessing(false)
    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Account</Title>
                </Col>
            </Row>
            <div className='mb-4'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link className='text-decoration-none' to="/dashboard">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Account Setting</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={16}>
                    <div className="card p-4">
                        <Title level={4} className="mb-3">Change Password</Title>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={12} lg={24}>
                                    <Form.Item label="Email" required>
                                        <Input placeholder="Email" value={user.email} disabled name="email" />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="New password" required>
                                        <Input.Password placeholder="New Password" name="newPassword" value={state.newPassword} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item label="Confirm password" required>
                                        <Input.Password placeholder="Confirm New Password" name="confirmPassword" value={state.confirmPassword} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} lg={8}>
                    <div className="card p-1">
                        <div>
                            <button className='btn btn-primary text-white w-100' disabled={isProcessing} onClick={handleChangePassword} >
                                {!isProcessing
                                    ? "Update password"
                                    : <span className='spinner-border spinner-border-sm'></span>
                                }</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
