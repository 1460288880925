import React from 'react'
import Footer from './Footer'
import MainFooter from './MainFooter'

export default function Index() {
    return (
        <>
            <MainFooter />
            <Footer />
        </>
    )
}
