// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    // apiKey: process.env.REACT_APP_FIREBASE_apiKey,
    // authDomain: process.env.REACT_APP_FIREBASE_authDomain,
    // projectId: process.env.REACT_APP_FIREBASE_projectId,
    // storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
    // appId: process.env.REACT_APP_FIREBASE_appId,
    // measurementId: process.env.REACT_APP_FIREBASE_measurementId
    apiKey: "AIzaSyC_E6uD3D02k-ltoFbS-xjrD8ulCGWk2Tk",
    authDomain: "dietetics-refill.firebaseapp.com",
    projectId: "dietetics-refill",
    storageBucket: "dietetics-refill.appspot.com",
    messagingSenderId: "9211614368",
    appId: "1:9211614368:web:4e6171c8906e78d513e5c5",
    measurementId: "G-YGGKM6H1DY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage }