import { message } from "antd";
import dayjs from "dayjs";

// Logos
import logo from "assets/images/logo.png"
import logoLight from "assets/images/logo.png"
import logoDark from "assets/images/logo.png"

window.appName = process.env.REACT_APP_NAME
window.appNameShort = process.env.REACT_APP_NAME
window.schoolName = process.env.REACT_APP_NAME
window.logo = logo
window.logoURL = "https://firebasestorage.googleapis.com/v0/b/seeraht-school.appspot.com/o/images%2Flogo.png?alt=media&token=bfec33cf-628d-4c90-bf5c-98dfeae55005"
window.logoLight = logoLight
window.logoDark = logoDark

window.getRandomId = () => Math.random().toString(36).slice(2)
window.getRandomRef = () => Math.random().toString().slice(2, 11)
window.getValidCNIC = cnic => cnic.trim().replace(/-/gi, '').replace(/_/gi, '').replace(/ /gi, '')

// eslint-disable-next-line
window.isEmail = email => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email);

window.timestampToDate = (date, format = "DD-MM-YYYY") => {
    return date.seconds ? dayjs(date.seconds * 1000).format(format) : "";
}
window.getDate = (date, format = "DD-MM-YYYY") => dayjs(date).format(format)

window.today = dayjs(new Date()).format("DD-MM-YYYY")
window.month = dayjs(new Date()).format("MM-YYYY")
window.year = new Date().getFullYear()

window.disabledDate = (current, type) => {
    let today = dayjs().format("YYYY-MM-DD");
    // console.log("current =>", current)
    // console.log("today greaterThan =>", dayjs(today, "YYYY-MM-DD HH:mm:ss"))

    if (type === "future") {
        return current && current > dayjs(today, "YYYY-MM-DD");
    } else {
        return current && current < dayjs(today, "YYYY-MM-DD");
    }
}

// https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
window.calculateAge = (dob) => {
    let ageDifMs = Date.now() - new Date(dob).getTime();
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

window.getDataFromHTML = (data, elementName) => {
    let html = new DOMParser().parseFromString(data, "text/xml");
    return html.getElementsByTagName(elementName)[0].innerHTML;
}

window.toCapitalizeCase = text => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

window.toastify = (msg, type) => {

    switch (type) {
        case "success":
            message.success(msg)
            break;
        case "error":
            message.error(msg)
            break;
        case "warning":
            message.warning(msg)
            break;
        default:
            message.info(msg)
    }
}