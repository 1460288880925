import React, { useState } from 'react'
import { firestore } from 'config/firebase';
// import { useAuthGlobalContext } from 'context/AuthContext';
import { doc, updateDoc, } from 'firebase/firestore';
import { Breadcrumb, Row, Col, Typography, Table, Divider, Space, Button, Tag, Dropdown, Modal } from 'antd'
import { MoreOutlined } from "@ant-design/icons"
import { Link, useNavigate } from 'react-router-dom';

const { Title } = Typography;

export default function AllProducts() {

    // const { isSuperAdmin } = useAuthGlobalContext()
    const [products, setProducts] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    let navigate = useNavigate()

    // const readProducts = useCallback(async () => {

    //     setIsProcessing(true)
    //     const collectionRef = collection(firestore, "products");
    //     const whereStatus = where("status", "==", "active");

    //     let q;

    //     if (isSuperAdmin) {
    //         q = query(collectionRef, whereStatus);
    //     }
    //     const querySnapshot = await getDocs(q);

    //     let array = []
    //     querySnapshot.forEach((docs) => {
    //         // doc.data() is never undefined for query doc snapshots
    //         let data = docs.data();
    //         let datecreated = docs.data().dateCreated;
    //         let date = new Date(datecreated.seconds * 1000 + datecreated.nanoseconds / 1000000);
    //         data.date = date.toLocaleString()
    //         data.key = data.id
    //         array.push(data)
    //     })
    //     setProducts(array)
    //     setIsProcessing(false)

    // }, [isSuperAdmin])

    // useEffect(() => {
    //     readProducts()
    //     // eslint-disable-next-line
    // }, [readProducts])


    const handleDelete = async (id) => {

        setIsProcessing(true)
        try {
            await updateDoc(doc(firestore, "products", id), { status: "deleted" })
            let activeBlogs = products.filter(item => item.id !== id);
            setProducts(activeBlogs)
            setIsModalVisible(false)
            setIsProcessing(false)
            window.toastify("A product successfully deleted", "success")
        } catch (error) {
            window.toastify("Something went wrong. Please try again.", "error")
        }
        setIsProcessing(false)

    }

    const columns = [
        {
            title: 'Image',
            render: (_, row) => {
                return (
                    <span>
                        {row.photo.map((images) => {
                            return <img key={images.id} className='img-fluid me-1' style={{ width: "2.25rem", height: "2.25rem" }} src={images.url} alt="img" />
                        })}
                    </span>
                )
            }
        },
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => a.title.length - b.title.length,
        },

        {
            title: 'Name',
            sorter: (a, b) => a.name.length - b.name.length,
            dataIndex: 'name',
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            sorter: (a, b) => a.category.length - b.category.length,
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            render: (_, row) => {
                return <div>   {row.stock === 0 ? <Tag color='error'>Out of stock</Tag> : row.stock} </div>
            },
            sorter: (a, b) => a.stock.length - b.stock.length,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            sorter: (a, b) => a.price.length - b.price.length,
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            sorter: (a, b) => a.discount.length - b.discount.length,
        },
        {
            title: 'Delivery charges',
            dataIndex: 'charges',
            sorter: (a, b) => a.charges.length - b.charges.length,
        },
        {
            title: 'Net price',
            dataIndex: 'netPrice',
            sorter: (a, b) => a.netPrice.length - b.netPrice.length,
        },
        {
            title: 'Sort order',
            dataIndex: 'sortOrder',
            sorter: (a, b) => a.sortOrder.length - b.sortOrder.length,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: (a, b) => a.date.length - b.date.length,
        },
        {
            title: 'Status',
            render: (_, row) => { return (<Tag color={row.productStatus === "active" ? "success" : "error"} className="text-capitalize">{row.productStatus}</Tag>) }
        },
        {
            title: 'Action',
            render: (_, row, index) => {
                return (
                    <>
                        <Dropdown
                            menu={{
                                items: [
                                    { label: "Edit", onClick: () => { navigate(row.id) } },
                                    { label: "Delete", onClick: () => { setIsModalVisible(index) } },
                                ]
                            }}
                            trigger={['click']}
                        >
                            <MoreOutlined className='text-primary' />
                        </Dropdown>
                        <Modal
                            title="Confirm Delete"
                            open={isModalVisible === index}
                            confirmLoading={isProcessing}
                            onOk={() => handleDelete(row.id)}
                            okButtonProps={{ style: { backgroundColor: '#ff4d4f', color: "white" } }}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <p>Are you sure you want to delete this item?</p>
                        </Modal>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">All Products</Title>
                </Col>
            </Row>
            <div className='mb-4'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link className='text-decoration-none' to="/dashboard">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>All Products</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row>
                <Col span={24}>
                    <div className="card">
                        <div className="card-body">

                            <Row>
                                <Col span={24} className="d-flex justify-content-end align-items-center">
                                    <Space size="small">
                                        <Button type='primary' onClick={() => navigate("add")}>Add</Button>
                                    </Space>
                                </Col>
                            </Row>

                            <Divider />

                            <Table
                                columns={columns}
                                dataSource={products}
                                loading={isProcessing}
                                bordered
                                scroll={{ x: true }}
                                expandable={{
                                    expandedRowRender: (record) => (<div className='px-5' >
                                        <h5>Description </h5>
                                        <p>{record.description}</p>
                                    </div>),
                                    rowExpandable: (record) => record.name !== 'Not Expandable',
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
