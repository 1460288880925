import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthGlobalContext } from 'context/AuthContext'
import InsufficientPermission from 'pages/Misc/InsufficientPermission'

export default function PrivateRoute({ Component, allowedRoles = [] }) {

  const { user, isAuthenticated } = useAuthGlobalContext()
  const location = useLocation()

  if (!isAuthenticated)
    return <Navigate to="/" state={{ from: location }} replace />

  if (!allowedRoles.length || user.roles.find(role => allowedRoles.includes(role)))
    return <Component />
  return <InsufficientPermission />
}
