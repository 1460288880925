import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from 'components/frontend/Header'
import Footer from 'components/frontend/Footer'

import Home from "./Home"
import Apply from "./Apply"
import Contact from "./Contact"
import Services from "./Services"
// import FAQs from './FAQs'
import About from './About'
import Appointment from './Appointment'
import NoPage from './NoPage'
import Blog from './Blog'

export default function Frontend() {
    return (
        <>
            <Header />
            <main>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='apply/*' element={<Apply />} />
                    <Route path='blog/*' element={<Blog />} />
                    <Route path='contact' element={<Contact />} />
                    <Route path='appointment' element={<Appointment />} />
                    <Route path='services' element={<Services />} />
                    {/* <Route path='faqs' element={<FAQs />} /> */}
                    <Route path='*' element={<NoPage />} />
                </Routes>
            </main>
            <Footer />
        </>
    )
}
