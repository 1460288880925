import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Product from './Product'
import Hero from './Hero/Hero'
import WebsiteSettings from './WebsiteSettings';
import Messages from './Messages'
import Appointments from './Appointments'
import Settings from './Settings'

export default function Index() {
    return (
        <div className="dashboard">
            <Routes>
                <Route path='/' element={<Hero />} />
                <Route path='products/*' element={<Product />} />
                <Route path='settings/*' element={<Settings />} />
                <Route path='website-settings/*' element={<WebsiteSettings />} />
                <Route path='messages' element={<Messages />} />
                <Route path='appointments' element={<Appointments />} />
            </Routes>
        </div>
    )
}
