import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Blog from './Blog'
import SingleBlog from './SingleBlog'

export default function index() {
    return (
        <>
            <Routes>
                <Route index element={<Blog />} />
                <Route path='id:' element={<SingleBlog />} />
            </Routes>
        </>
    )
}
