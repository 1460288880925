import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import { AiFillHome } from 'react-icons/ai';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { FaComments } from 'react-icons/fa'

const { Title, Paragraph, Text } = Typography;

const data = [
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/10.jpg', title: "Make Healthy Choices To Stay Looking Fine" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/5.jpg', title: "Give Your Body & Mind The Proper Nutrients" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/12.jpg', title: "Good Nutrition Makes A Healthy Body And Mind" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/4.jpg', title: "A Healthy Diet Is A Solution Of Our Health Problems" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/2.jpg', title: "Don't Give Up On Your Favorite Meal, Make Healthy" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/7.jpg', title: "Tips For Staying Fit And Healthy On Vacations" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/9.jpg', title: "Ways To Maintain Health Blood Sugar" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/3.jpg', title: "The Power Of Simple Lifestyle Changes" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/11.jpg', title: "Being Happy Is The Best Way To Grow Your Health" },
  { url: 'https://peacefulqode.com/themes/nutritius/html/images/blog/6.jpg', title: "How Much Do Eat Health Food You Really Need Day?" },
]

export default function Blog() {

  const navigate = useNavigate();

  return (
    <>
      <div className="blog-hero d-flex align-items-center">
        <div className="container">
          <Row>
            <Col span={24}>
              <Title className='text-primary mb-0'>BLOG</Title>
              <div className="d-flex align-items-center">
                <Title level={5} className='text-primary mt-3 d-flex align-items-center cursor-pointer' onClick={() => navigate('/')} ><AiFillHome color='#08220f' className='me-2' size={18} /> Home </Title>
                <Title level={5} className='text-success ms-2 mt-3 d-flex align-items-center cursor-pointer'><MdOutlineKeyboardArrowRight color='#08220f' className='me-2' size={20} /> Blog </Title>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container py-6">
        <Row gutter={[16, 36]}>

          {data.map((item, i) => <Col md={24} lg={12} key={i}>
            <div className="card main card-hover">
              <Image src={item.url} className='card-img' preview={false} />
              <div className="p-2 p-md-4">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Text className="bg-success d-inline-block p-2 text-white fw-bold">30 AUG</Text>
                  <Text className='d-block fs-6'><FaComments className='text-success me-2 mb-1' size={18} /><span>0</span> Comments</Text>
                </div>
                <Title className='' level={4}>{item.title}</Title>
                <Paragraph ellipsis={{ rows: 2 }} className='fs-6 mb-3'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</Paragraph>
                <Link className='text-success fw-bold' to='/blog/id:'>READ MORE</Link>
              </div>
            </div>
          </Col>)}

        </Row>
      </div>
    </>
  )
}
