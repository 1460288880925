import React, { useState } from 'react'
import { Breadcrumb, Button, Col, Form, Input, Row, Select, Tooltip, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
// import { useAuthGlobalContext } from 'context/AuthContext';
import { doc, runTransaction, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { EditOutlined, CloseOutlined, InboxOutlined } from "@ant-design/icons"

const initialValue = { name: "", category: "", sortOrder: 1, description: "" }
const { Title } = Typography;

export default function AddProduct() {

    // const { user } = useAuthGlobalContext();
    const [state, setState] = useState(initialValue);
    const [files, setFiles] = useState([]);
    const [isSlugEditable, setIsSlugEditable] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();

    // Dropzone
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        multiple: true,
        onDrop: acceptedfiles => {
            const updatedFiles = acceptedfiles.map((file) => {
                return { id: window.getRandomId(), file: file };
            });
            setFiles([...files, ...updatedFiles])
        }
    });

    const handleChange = e => {
        const { name, value } = e.target
        setState(s => ({ ...s, [name]: value }))

        if (name === "name") {
            let slug = window.getSlug(value)
            setState(s => ({ ...s, slug }))
        }

        if (name === "price" || name === "discount" || name === "charges") {
            setState(s => {
                let price = parseFloat(s.price);
                let discount = parseFloat(s.discount);
                let charges = parseFloat(s.charges);
                let discountAmount = price * (discount / 100)
                let netPrice = Math.round(price - discountAmount + charges);
                return { ...s, netPrice };
            });
        }

    }

    const handleProductStatus = (val) => {
        setState(s => ({ ...s, productStatus: val }))
    }

    const handleSubmit = () => {

        let { name, slug, category, stock, price, discount, charges, netPrice, productStatus, sortOrder, description } = state;

        if (name.length < 3) { return window.toastify("Please enter product name", "error") }
        if (!slug) { return window.toastify("Please enter slug", "error") }
        if (category.length < 3) { return window.toastify("Please enter category", "error") }
        if (!stock) { return window.toastify("Please enter product stock", "error") }
        if (!price) { return window.toastify("Please enter product price", "error") }
        if (!discount) { return window.toastify("Please enter product discount", "error") }
        if (!charges) { return window.toastify("Please enter product charges", "error") }
        if (!productStatus) { return window.toastify("Please enter product status", "error") }
        if (!description) { return window.toastify("Please enter product description", "error") }
        if (!files) { return window.toastify("Please upload file", "error") }

        name = name.trim()
        slug = slug.trim()
        category = category.trim()
        stock = Number(stock)
        price = Number(price)
        discount = Number(discount)
        charges = Number(charges)
        netPrice = Number(netPrice)
        sortOrder = Number(sortOrder)
        description = description.trim()

        let formData = {
            name, slug, category, stock, price, discount, charges, netPrice, productStatus, sortOrder, description,
            dateCreated: serverTimestamp(),
            id: window.getRandomId(),
            status: "active",
        }
        // formData.createdBy = {
        //     fullName: user.name,
        //     email: user.email,
        //     uid: user.uid
        // }
        setIsProcessing(true)
        createBlogLength(formData)
    }

    const createBlogLength = async (formData) => {

        setIsProcessing(true)
        const docRef = doc(firestore, "transactions", "products");
        try {
            await runTransaction(firestore, async (transaction) => {
                const sfDoc = await transaction.get(docRef);
                const newPopulation = sfDoc.data().blog + 1;
                transaction.update(docRef, { products: newPopulation });
                uploadFile(formData)

            });
        } catch (e) {
            console.log(e)
            setIsProcessing(false)
            window.toastify("Something went wrong while generating blog number", "error")
        }
    }

    const uploadFile = (formData) => {

        let fileDataArray = [];
        for (let i = 0; i < files?.length; i++) {

            const fileData = files[i];
            const randomId = window.getRandomId();
            const ext = fileData.file.name.split('.').pop()
            const pathwithFileName = `${randomId}/images/photo${i}.${ext}`

            const fileRef = ref(storage, pathwithFileName);

            const uploadTask = uploadBytesResumable(fileRef, fileData.file);
            uploadTask.on("state_changed", (snapshot) => {
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
                (error) => {
                    console.error(error)
                    window.toastify("Something went wrong while uploading photo.", "error")
                    setIsProcessing(false)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                        fileDataArray.push({ url: downloadURL, id: randomId, size: fileData.file.size, path: fileData.file.path })
                        if (fileDataArray.length === files.length) {
                            const newData = { ...formData, photo: fileDataArray }
                            createDocument(newData)
                        }
                    });
                }
            )
        }
    }

    const createDocument = async (formData) => {

        const docRef = doc(firestore, "products", formData.id)
        try {
            await setDoc(docRef, formData)
            setIsProcessing(false)
            navigate("/dashboard/products")
            window.toastify("A new product has been successfullfy added", "success")
        } catch (error) {
            console.log(error)
            window.toastify("Something went wrong. Please try again", "error")
        }
        setIsProcessing(false)
    }

    const removeFile = (id) => {
        const newfiles = [...files];
        let filterFile = newfiles.filter((item) => item.id !== id)
        setFiles(filterFile);
    };

    return (
        <div className='content-min-vh'>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Product</Title>
                </Col>
            </Row>
            <div className='mb-3'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/dashboard" className='text-decoration-none'>Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Add New Product</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <Row gutter={16} >
                <Col xs={24} xl={16} className="mb-4">
                    <div className="card p-4">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Add New Product</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Product name" required>
                                        <Input placeholder='Product Name' name='name' onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Slug" required>
                                        <Input.Group compact className='d-flex'>
                                            <Input placeholder="Slug" name="slug" disabled={isSlugEditable} value={state.slug} onChange={handleChange} style={{ width: "calc(100% - 32px)" }} />
                                            <Tooltip title={isSlugEditable ? "Edit" : "Cancel Edit"}>
                                                <Button onClick={() => { setIsSlugEditable(!isSlugEditable) }} icon={isSlugEditable ? <EditOutlined /> : <CloseOutlined />} />
                                            </Tooltip>
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Category" required>
                                        <Input placeholder='Category' name='category' onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Stock" required>
                                        <Input type='number' min={1} placeholder="Stock" name="stock" onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Price" required>
                                        <Input type='number' placeholder="Price" name="price" onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Discount %" required>
                                        <Input type='number' placeholder="10%" disabled={!state.price} name="discount" onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Delivery Charge" required>
                                        <Input type='number' placeholder="Delivery Charge" disabled={!state.discount} name="charges" onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Net Price" required>
                                        <Input type='number' placeholder="Net Price" name="netPrice" disabled value={state.netPrice} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Status" required>
                                        <Select
                                            showSearch
                                            className='w-100%'
                                            placeholder="Select to status"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            onChange={(val) => handleProductStatus(val)}
                                            options={[
                                                { value: 'active', label: 'Active', },
                                                { value: 'inactive', label: 'In Active', },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={24}>
                                    <Form.Item label="Description">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Description" name="description" maxLength={200} showCount onChange={handleChange} />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <Form layout="vertical">
                            <Form.Item label="Picture (1000*1000) px" className='mb-0 text-center'>
                                <>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <InboxOutlined />
                                        <input name='photo' {...getInputProps()} />
                                        <p className='mb-0'>Drop files here or click to upload.</p>
                                    </div>
                                </>
                            </Form.Item>
                        </Form>
                    </div>
                    <div>
                        {files &&
                            files?.map((files, index) => {
                                let sizeInKB = Math.round(files.file.size / 1024);
                                return (
                                    <Col key={files.id} xs={24} className='p-0'>
                                        <div className='card shadow-lg p-2 border mb-2'>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='d-flex'>
                                                    <img src={URL.createObjectURL(files.file)} className='img-fluid rounded-1 avatar-sm me-2' alt="img" />
                                                    <div>
                                                        <p className='fw-bold opacity-75 p-0 m-0'>{files.file.path}</p>
                                                        <p className='p-0'>{sizeInKB} KB</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn ' onClick={() => removeFile(files.id)}><CloseOutlined /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                    </div>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleSubmit}>
                            {!isProcessing
                                ? "Add"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
