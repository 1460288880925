import React, { useState } from 'react'
import { Col, Image, Input, Row, Typography } from 'antd'
// import { ImLocation } from 'react-icons/im'
import { AiTwotoneMail } from 'react-icons/ai'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { useAuthGlobalContext } from 'context/AuthContext';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from 'config/firebase';

const { Title, Text } = Typography;
const { TextArea } = Input;

const initialState = { fullName: '', email: '', phoneNumber: '', subject: '', message: '', }

const data = [
  // { icon: ImLocation, title: 'Our Location', text: 'The Queens Walk, Bishops, London SE1 7PB, UK', },
  { icon: AiTwotoneMail, title: 'Mail Us', text: 'info@dieteticsrefill.com', url: 'mailto:info@dieteticsrefill.com' },
  { icon: BsFillTelephoneFill, title: 'Our Contact ', url: 'tel:+9203136994019', text: '+92 313 699 40 19' },
]

export default function Contact() {

  const { user } = useAuthGlobalContext()

  const [state, setState] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)


  const handleChange = (e) => {
    setState(s => ({ ...s, [e.target.name]: e.target.value }))
  }


  const handleSubmit = (e) => {
    e.preventDefault()

    if (user.uid) {

      let { fullName, email, phoneNumber, subject, message, } = state;
      fullName = fullName.trim()
      subject = subject.trim()
      message = message.trim()

      if (fullName.length < 3) { return window.toastify('Please Enteryour the Name', "error"); }
      if (!window.isEmail(email)) { return window.toastify("Please enter a valid email address", "error") }
      if (subject.length < 3) { return window.toastify('Please Enter your the subject', "error"); }
      if (message.length < 10) { return window.toastify('Please Enter your the message', "error"); }

      let formData = { fullName, email, phoneNumber, subject, message, status: 'active', isunRead: true, sound: false }
      formData.dateCreated = serverTimestamp()
      formData.id = window.getRandomId()
      formData.createdBy = {
        email: user.email,
        uid: user.uid
      }

      setIsLoading(true)
      createDoc(formData);
    } else {
      // navigate('/auth/login')
      window.toastify("First you need Login", "error")
    }

  }
  const createDoc = async (formData) => {
    try {
      await setDoc(doc(firestore, "messages", formData.id), formData)
      setIsLoading(false)
      setState(initialState)
      window.toastify("Yours Message has been successfully Submit", "success")
    } catch (error) {
      window.toastify("Something went wrong while creating document.")
    }
  }



  return (
    <>
      <div className="container py-5">
        <Row gutter={[16, 16]}>

          {data.map((item, i) => <Col xs={24} md={24} lg={12} key={i}>
            <div className="bg-secondary py-5 p-4 text-center rounded-3 card-shahdow" style={{ minHeight: 285 }}>
              <div className="bg-dark d-inline-block p-3 rounded-circle"><item.icon color='#ece4d9' size={28} /></div>
              <Title className='mt-3' level={2}>{item.title}</Title>
              <a href={item.url} target="_blank" rel="noopener noreferrer" className='fs-5 text-decoration-none text-black'>{item.text}</a>
            </div>
          </Col>)}

        </Row>
      </div>

      <div className="bg-secondary py-5 contact-card">
        <div className="container">
          <Row gutter={[16, 24]}>

            <Col xs={24} md={24} lg={10} >
              <Image src='https://peacefulqode.com/themes/nutritius/html/images/protfolio/6.jpg' preview={false} height='100%' width='100%' />
            </Col>

            <Col xs={24} md={24} lg={14} >
              <div className="p-0 p-lg-4">
                <Title level={5} className='text-success'>LET'S TALK</Title>
                <Title level={1} className='mt-2'>Get In Touch</Title>
                <Text className='d-block fs-6 mb-4'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</Text>

                <Row gutter={[18, 16]}>

                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12}><Input placeholder='Your Name' name='fullName' value={state.fullName} className='p-3 bg-white border-1 rounded-0 border-white' onChange={handleChange} /></Col>
                      <Col xs={24} md={12}><Input placeholder='Phone Number' name='phoneNumber' value={state.phoneNumber} className='p-3 bg-white border-1 rounded-0 border-white' onChange={handleChange} /></Col>
                    </Row>
                  </Col>

                  <Col span={24}><Input placeholder='ؑEmail Address' name='email' value={state.email} className='p-3 bg-white border-1 rounded-0 border-white' onChange={handleChange} /></Col>

                  <Col span={24}><Input placeholder='Subject' name='subject' value={state.subject} className='p-3 bg-white border-1 rounded-0 border-white' onChange={handleChange} /></Col>

                  <Col span={24}>
                    <TextArea placeholder='Write Your Message' rows={4} name='message' value={state.message} className='p-3 mb-4 bg-white border-1 rounded-0 border-white' onChange={handleChange} />
                    <button className='btn-light w-50 d-block mx-auto mt-3 fw-bold' onClick={handleSubmit}>
                      {isLoading
                        ? "LOADING..."
                        : "SEND MESSAGE"
                      }
                    </button>
                  </Col>

                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* <Row>
        <Col span={24}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3405.3549768102284!2d73.113995!3d31.404344499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392269152747a7cb%3A0x6e606a23a966744d!2zU0VFUkFIVCDYs9uM2LHbgw!5e0!3m2!1sen!2s!4v1675261431648!5m2!1sen!2s" width="100%" height="100%" style={{ border: 0, minHeight: "60vh" }} loading="lazy" title='Location Map'></iframe>
        </Col>
      </Row> */}

    </>


  )
}
