import React, { useEffect } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { useAuthGlobalContext } from '../context/AuthContext'
import Frontend from './Frontend'
import Auth from "./Auth"
import PrivateRoute from 'components/PrivateRoute'
import Dashboard from "./Dashboard"
// import Dashboard from "./Dashboard"
// import PrivateRoute from "components/PrivateRoute"

export default function Index() {

    const { isAuthenticated } = useAuthGlobalContext()

    const Wrapper = ({ children }) => {
        const location = useLocation()
        useEffect(() => {
            document.documentElement.scrollTo(0, 0)
        }, [location.pathname])
        return children
    }

    return (
        <Wrapper>
            <Routes>
                <Route path='/*' element={<Frontend />} />
                <Route path='/auth/*' element={<Auth />} />
                {/* <Route path='/dashboard/*' element={<Dashboard />} /> */}
                <Route path='/auth/*' element={!isAuthenticated ? <Auth /> : <Navigate to="/dashboard" replace />} />
                <Route path='/dashboard/*' element={<PrivateRoute Component={Dashboard} allowedRoles={["superAdmin"]} />} />
            </Routes>
        </Wrapper>
    )
}
