import React, { useState } from 'react'
import { Col, Input, Row, Typography, Image, Space, Divider } from 'antd'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { AiFillInstagram, AiOutlineMail, AiFillPhone } from 'react-icons/ai';
import { MdKeyboardArrowRight, } from 'react-icons/md';
import logo from '../../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from 'config/firebase';
// MdLocationPin
const { Title, Text } = Typography;

const usefulLinks = [
    { url: "/", text: 'Home' },
    { url: "about", text: 'About us' },
    { url: "contact", text: 'Contact us' },
    { url: "blog", text: 'Blog' },
    { url: "appointment", text: 'Appointment' },
]

const usefulLinkstwo = [
    { url: "#", text: 'Personalized Nutrition' },
    { url: "#", text: 'Balance Body & Mind' },
    { url: "#", text: 'Child Nutrition' },
    { url: "#", text: 'Fitness Performance' },
    { url: "#", text: 'Weight Loss Programs' },
]

const socialMediaLinks = [
    { url: "https://www.facebook.com/dieteticsrefillamna", icon: FaFacebookF },
    { url: "https://www.instagram.com/dt._amna_akmal/", icon: AiFillInstagram },
    { url: "https://www.linkedin.com/in/dieteticsrefill/", icon: FaLinkedinIn },
    { url: "#", icon: FaTwitter },
]

export default function MainFooter() {

    const [state, setState] = useState({ email: "" })
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()

        let email = state;
        if (!window.isEmail(email)) { return window.toastify("Please enter a valid email address", "error") }

        let formData = { email, dateCreated: serverTimestamp(), id: window.getRandomId(), status: "active" }

        setIsLoading(true)
        createDoc(formData);

    }

    const createDoc = async (formData) => {
        try {
            await setDoc(doc(firestore, "subscribers", formData.id), formData)
            setIsLoading(false)
            setState({ email: "" })
            window.toastify("Yours Email has been successfully Subcribers", "success")
        } catch (error) {
            window.toastify("Something went wrong while creating document.")
        }
    }

    return (
        <footer className='bg-primary'>

            <div className="container py-5 sub-footer">
                <Row gutter={[8, 16]}>

                    <Col xs={24} md={10} className='bg-primary'>
                        <Title className='text-white text-center m-0 mb-3 mb-lg-0'>Subscribe Newsletter</Title>
                    </Col>

                    <Col xs={24} md={14}>
                        <div className='d-block d-md-flex justify-conetent-center align-items-center ms-3 px-3 px-md-0'>
                            <Input placeholder='Your email address' name='email' value={state.email} onChange={(e) => setState(e.target.value)} className='p-3 bg-dark text-white border-0 me-0 me-md-3 rounded-0' />
                            <button className='border-0 mt-3 mt-md-0 d-block mx-auto px-4 text-white fs-5' onClick={handleSubmit} style={{ paddingBottom: 12, paddingTop: 12, background: '#7fb82d' }}>
                                {isLoading
                                    ? "LOADING.."
                                    : "SUBSCRIBE"
                                }
                            </button>
                        </div>
                    </Col>

                </Row>
            </div>

            <Divider type='horizontal' className='border-secondary' style={{ borderWidth: 1 }} />

            <div className="container-xl py-4 px-3">
                <Row gutter={[16, 16]}>

                    <Col xs={24} md={12} lg={6}>
                        <div className="d-flex justify-content-start align-items-center">
                            <Image src={logo} preview={false} width={70} height={70} />
                            <Title className='text-white' level={4}>Dietetics Refill</Title>
                        </div>

                        <Text className='mt-2 d-block text-white fs-6 text-justify'>It helps designers plan whererthy the content will sitcont ent to be written and approved.</Text>

                        <Space size='middle' className='mt-5 mb-4 '>
                            {socialMediaLinks.map((item, i) => {
                                return <a key={i} href={item.url} target="_blank" rel="noopener noreferrer" className='bg-dark p-3'><item.icon color='#fff' size={18} /></a>
                            })}
                        </Space>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <div className="p-2">
                            <Title level={3} className='text-white'>Quick Link</Title>

                            {usefulLinks.map((item, i) => <Link key={i} to={item.url} className='text-white my-3 d-block text-decoration-none fs-6' ><MdKeyboardArrowRight size={20} color='#7fb82d' /> {item.text}</Link>)}

                        </div>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <div className="p-2">
                            <Title level={3} className='text-white'>Our Service</Title>

                            {usefulLinkstwo.map((item, i) => <Link key={i} to={item.url} className='text-white my-3 d-block text-decoration-none fs-6' ><MdKeyboardArrowRight size={20} color='#7fb82d' /> {item.text}</Link>)}

                        </div>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <div className="p-2">
                            <Title level={3} className='text-white'>Contact Us</Title>

                            {/* <div className="d-flex justify-content-start align-items-center">
                                <MdLocationPin className='me-3 mb-3' color='#ece4d9' size={38} />
                                <p className='text-decoration-none d-block fs-6 text-white'>The Queen's Walk, Bishop's, London SE1 7PB, Kingdom</p>
                            </div> */}

                            <a href='mailto:info@dieteticsrefill.com' className='text-decoration-none d-block mb-3 fs-6 text-white'> <AiOutlineMail className='me-3' color='#ece4d9' size={20} />info@dieteticsrefill.com</a>
                            <a href="tel:+9203136994019" className='text-decoration-none text-white d-block my-3 fs-6'><AiFillPhone className='me-3' color='#ece4d9' size={20} /> +92 313 699 40 19</a>

                        </div>
                    </Col>

                </Row>
            </div>
        </footer >
    )
}
