import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import icon1 from '../../../assets/images/icon2.png'
import icon2 from '../../../assets/images/icon1.png'
import icon3 from '../../../assets/images/nutrition.png'

const { Title, Text } = Typography

const data = [
    { title: 'Post Pregnancy', category: 'Healty Food, For Breakfast', icon: icon1, price: '25.00', class: 'btn-dark' },
    { title: 'Body Sculpting', category: 'No sugar and gluten', icon: icon2, price: '35.00', class: 'btn-secondary' },
    { title: 'Weight Loss', category: 'Exact Calories Healthy', icon: icon3, price: '48.00', class: 'btn-dark' },
]

export default function Prices() {
    return (

        <div className='bg-secondary'>
            <div className="container py-5">

                <Title level={4} className='text-success text-center'>DIET PLANS</Title>
                <Title level={1} className='mt-0 text-center'>Choose Your Perfect Plan</Title>

                <Row gutter={[16, 26]} className='mt-5'>
                    
                    {data.map((item, i) => <Col xs={24} md={24} lg={8} key={i} className=''>
                        <div className="bg-white p-4 rounded-3 card-shahdow text-center">
                            <Title level={3}>{item.title}</Title>
                            <Text className='fs-6 d-block mb-4'>{item.category}</Text>

                            <Image src={item.icon} width={70} height={70} preview={false} />
                            <Title level={1} className='mt-3'>${item.price}<span className='fs-6'>/ Month</span> </Title>

                            <Text className='fs-6 d-block mb-3'>with 7 day free trail</Text>
                            <Text className='fs-6 d-block mb-3'>Nutrition Strategies</Text>
                            <Text className='fs-6 d-block mb-3'>Health diet plan</Text>
                            <Text className='fs-6 d-block mb-4'>Motivation program</Text>

                            <button className={`${item.class} mb-3`}>CHOOSE PLAN</button>

                        </div>
                    </Col>)}

                </Row>

            </div>
        </div>

    )
}
