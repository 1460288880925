import React from 'react'

import Hero from './Hero'
import Contact from './Contact'

export default function index() {
    return (
        <>
            <Hero />
            <Contact />
        </>
    )
}
