import React, { useState } from 'react'
import { Col, Form, Input, Rate, Row, Typography } from 'antd'
import { useDropzone } from 'react-dropzone';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Title } = Typography;
const initialState = { firstName: "", lastName: "", title: "", rating: 0, sortOrder: 1, description: "" }

export default function AddTestimonial() {

    const { user } = useAuthGlobalContext()
    console.log('user', user)
    const [state, setState] = useState(initialState);
    const [photo, setPhoto] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    let navigate = useNavigate();


    const { getRootProps: getRootPhotoProps, getInputProps: getInputPhotoProps } = useDropzone({
        accept: { 'image/*': [] }, multiple: false,
        onDrop: acceptedFiles => { setPhoto(acceptedFiles[0]) }
    });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleRateChange = (val) => {
        setState(s => ({ ...s, rating: val }))
    }

    const handleSubmit = () => {
        let { firstName, lastName, title, rating, sortOrder, description } = state;

        firstName = firstName.trim();
        lastName = lastName.trim();
        let fullName = (firstName + " " + lastName).trim();
        title = title.trim();
        rating = Number(rating)
        sortOrder = Number(sortOrder);
        description = description.trim();

        if (firstName.length < 3) { return window.toastify("Please enter first name correctly", "error") }
        if (!title) { return window.toastify("Please enter professional title", "error") }
        if (!sortOrder) { return window.toastify("Please enter sort order", "error") }
        if (!rating) { return window.toastify("Please enter rating", "error") }
        if (!description) { return window.toastify("Please enter description", "error") }
        if (!photo) { return window.toastify("Please upload testimonial photo", "error") }
        if (photo.size > 1048576) { return window.toastify("File size should be less than 1MB", "error") }

        const formData = {
            firstName, lastName, fullName, title, rating, sortOrder, description,
            status: "active",
            id: window.getRandomId(),
            dateCreated: serverTimestamp(),
            createdBy: {
                fullName: user.fullName,
                email: user.email,
                uid: user.uid
            }
        }
        setIsProcessing(true)
        uploadPhoto(formData)

    }

    const uploadPhoto = (formData) => {

        const ext = photo.name.split('.').pop()
        const pathwithFileName = `${formData.id}/images/photo.${ext}`

        const fileRef = ref(storage, pathwithFileName);

        const uploadTask = uploadBytesResumable(fileRef, photo);

        uploadTask.on("state_changed", (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
            (error) => {
                console.error(error)
                window.toastify("Something went wrong while uploading photo.", "error")
                setIsProcessing(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let obj = { url: downloadURL, size: photo.size }
                    let data = { ...formData, photo: obj }
                    createDocument(data)
                });
            }
        )
    }

    const createDocument = async (formData) => {

        const docRef = doc(firestore, "testimonial", formData.id)
        try {
            await setDoc(docRef, formData)
            navigate("/dashboard/website-settings/testimonials")
            window.toastify("A new testimonial has been successfullfy added", "success")
        } catch (error) {
            console.log(error)
            window.toastify("Something went wrong. Please try again", "success")
        }
        setIsProcessing(false)

    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Testimonial</Title>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col xs={24} xl={16} >
                    <div className="card p-4 mb-3">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Add New Testimonial</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="First Name" required>
                                        <Input placeholder='First Name' name='firstName' onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Last Name" required>
                                        <Input placeholder='Last Name' name='lastName' onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Professional Title" required>
                                        <Input placeholder='Professional Title' name='title' onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div className='mb-3'>
                                        <Title level={5} className="mb-0"> Star Rating</Title>
                                        <Rate className='fs-3' onChange={handleRateChange} />
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={24}>
                                    <Form.Item label="Description">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Description" name="description" maxLength={200} showCount onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <Form layout="vertical">
                            <Form.Item label="Picture (1000*1000) px" className='mb-0 text-center'>
                                <>
                                    {photo
                                        ? <img src={URL.createObjectURL(photo)} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                        : <img src={"https://dummyimage.com/1000x1000/"} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                    }

                                    <div {...getRootPhotoProps({ className: 'dropzone p-1 mt-2' })}>
                                        {/* <InboxOutlined /> */}
                                        <input name='photo' {...getInputPhotoProps()} />
                                        <p className='mb-0'>Click or drag file</p>
                                    </div>
                                </>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleSubmit}>
                            {!isProcessing
                                ? "Add"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}
