import './App.scss';
import "bootstrap/dist/js/bootstrap.bundle"
import Routes from "./pages/Routes"
import { useAuthGlobalContext } from './context/AuthContext';
import ScreenLoader from "./components/ScreenLoader"
import { FloatButton, ConfigProvider } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons'

function App() {

  const { isAppLoading } = useAuthGlobalContext()
  if (isAppLoading)
    return <ScreenLoader />

  return (
    <>
      <ConfigProvider>
        <Routes />
      </ConfigProvider>
      <FloatButton
        icon={<WhatsAppOutlined />}
        onClick={() => window.open("https://wa.me/923136994019")}
        className="whatsapp-fab"
        tooltip="WhatsApp"
      />
    </>
  );
}

export default App;
