import React, { useCallback, useEffect, useRef, useState } from 'react';
import { firestore } from 'config/firebase';
import { useAuthGlobalContext } from 'context/AuthContext';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { MessageFilled, UserOutlined, SettingOutlined } from '@ant-design/icons'
import { BiMessageDetail } from 'react-icons/bi'
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import audioSound from "assets/audioplay.mp3"

export default function Header() {

    const { user, handlLogOut } = useAuthGlobalContext();
    const [messages, setMessages] = useState([]);
    const [apppointments, setApppointments] = useState([]);
    const messageSound = useRef()

    const readMessages = useCallback(async () => {

        const collectionRef = query(collection(firestore, "messages"), where("isunRead", "==", true))
        const querySnapshot = await getDocs(collectionRef);

        let array = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let data = doc.data();
            array.push(data)
        })
        setMessages(array)
    }, [])

    useEffect(() => {
        readMessages()
    }, [readMessages])

    const handleContactSound = async () => {

        // Check if there are any messages that need to play a sound
        const messagesToPlaySound = messages.filter(message => !message.sound);
        if (messagesToPlaySound.length === 0) {
            return; // No messages to play sound for
        }

        // Play sound and display notification for each message
        for (const message of messagesToPlaySound) {
            messageSound.current.play()
            message.sound = true;
            try {
                await setDoc(doc(firestore, "massages", message.id), message, { merge: true })
                window.toastify(`You have a new message sent from ${message.fullName}`, "success")
            } catch (error) {
                message.error("Something went wrong. Please try again")
            }
        }
    }

    useEffect(() => {
        handleContactSound()
        // eslint-disable-next-line
    }, [messages])

    // Appointments Message Function

    const readAppointments = useCallback(async () => {

        const collectionRef = query(collection(firestore, "appointments"), where("isunRead", "==", true))
        const querySnapshot = await getDocs(collectionRef);

        let array = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let data = doc.data();
            array.push(data)
        })
        setApppointments(array)
    }, [])

    useEffect(() => {
        readAppointments()
    }, [readAppointments])

    const handleAppointmentsSound = async () => {

        // Check if there are any messages that need to play a sound
        const appointmentsToPlaySound = apppointments.filter(apppointments => !apppointments.sound);
        if (appointmentsToPlaySound.length === 0) {
            return; // No messages to play sound for
        }

        // Play sound and display notification for each message
        for (const appointments of appointmentsToPlaySound) {
            messageSound.current.play()
            appointments.sound = true;
            try {
                await setDoc(doc(firestore, "appointments", appointments.id), appointments, { merge: true })
                window.toastify(`You have a new message sent from ${appointments.patientName}`, "success")
            } catch (error) {
                appointments.error("Something went wrong. Please try again")
            }
        }
    }

    useEffect(() => {
        handleAppointmentsSound()
        // eslint-disable-next-line
    }, [apppointments])


    return (
        <>
            <div>
                <audio ref={messageSound} src={audioSound}></audio>
            </div>
            <header className='text-white position-sticky top-0 ' style={{ zIndex: 100 }}>
                <nav className="navbar navbar-expand-lg  navbar-dark bg-primary shadow">
                    <div className="container-fluid">
                        <Link to="/"><img src={window.logoLight} width={50} height={50} alt={`${window.appName} Logo`} className="navbar-brand p-0 rounded-cricle" /> </Link>
                        <div className="d-flex">
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav me-auto">
                                    <li className="nav-item dropdown me-3">
                                        <button className="nav-link dropdown-toggle border-0 bg-transparent d-flex align-items-center p-0" data-bs-toggle="dropdown">
                                            <span className="text-white d-inline-block me-2 text-end">
                                                <small className="fw-bold">{user.fullName}</small>
                                                {/* <small>Super Admin</small> */}
                                            </span>
                                            {
                                                !user?.photo ?
                                                    <img src="https://res.cloudinary.com/dufkxmegs/image/upload/v1679915607/avatar_vxqxen.png" className='rounded-circle avatar' alt='Profile Pic' />
                                                    :
                                                    <img src={user.photo.url} alt="Profile Pic" width={40} height={40} className='rounded-circle' />
                                            }
                                        </button>
                                        <ul className="dropdown-menu pb-0">
                                            <li>

                                                <Link to="/dashboard/settings/profile" className="dropdown-item d-flex justify-content-center align-items-center "><UserOutlined className="me-2" /> My Profile</Link>

                                            </li>
                                            <li><Link to="/dashboard/settings/account" className="dropdown-item d-flex justify-content-center align-items-center "><SettingOutlined className="me-2" /> Account Settings</Link></li>
                                            <li><button className="btn btn-danger btn-sm w-100 text-white" onClick={() => handlLogOut()} >Logout</button></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><Link to="/dashboard/messages" className="nav-link">
                                        <Badge count={messages.length === 0 ? 0 : messages.length} color="#52c41a">
                                            <MessageFilled className="fs-5 text-white" />
                                        </Badge>
                                    </Link></li>

                                    <li className="nav-item"><Link to="/dashboard/appointments" className="nav-link">
                                        <Badge count={apppointments.length === 0 ? 0 : apppointments.length} color="#52c41a">
                                            <BiMessageDetail className="fs-5 mb-1 text-white" />
                                        </Badge>
                                    </Link></li>
                                    {/* <li className="nav-item"><Link to="/dashboard/notifications" className="nav-link"><i className="fa-regular fa-bell"></i></Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
