import React from 'react'
import Hero from './Hero'
import Appointment from './Appointment'
import Testimonial from '../Home/Testimonials'

export default function index() {
    return (
        <>
            <Hero />
            <Appointment />
            <Testimonial />
        </>
    )
}































