import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddProduct from './Add'
import AllProducts from './All'
import EditProduct from './Edit'

export default function index() {
    return (
        <Routes>
            <Route index element={<AllProducts />} />
            <Route path='add' element={<AddProduct />} />
            <Route path=':id' element={<EditProduct />} />
        </Routes>
    )
}
