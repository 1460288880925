import React, { useState, useEffect, useCallback } from 'react'
import { Col, Image, Rate, Row, Typography } from 'antd';
import Slider from "react-slick";
import { firestore } from 'config/firebase';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';

const { Title, Paragraph } = Typography;

let settings = {
  dots: true,
  autoplay: true,
  speed: 500,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  responsive: [
    { breakpoint: 991, settings: { slidesToShow: 2 } },
    { breakpoint: 767, settings: { slidesToShow: 1 } }
  ]
}

export default function Testimonial() {

  const [testimonial, setTestimonial] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const readTestimonial = useCallback(async () => {
    setIsLoading(true)
    const collectionRef = query(collection(firestore, "testimonial"), where("status", "==", "active"),orderBy("sortOrder","asc"))
    const querySnapshot = await getDocs(collectionRef);

    let array = [];
    querySnapshot.forEach((docs) => {
      let data = docs.data()
      data.key = data.id
      array.push(data)
    })
    setTestimonial(array)
    setIsLoading(false)

  }, [])

  useEffect(() => {
    readTestimonial()
  }, [readTestimonial])

  return (
    <div className="py-5 home-testimonials">
      <div className="container">

        <Title level={4} className='text-success text-center'>TESTIMONIALS</Title>
        <Title level={1} className='text-center mt-0'>What Our Customer Say</Title>

        <Row gutter={[16, 16]} className='mt-5'>
          <Col span={24}>
            {!isLoading
              ? (
                <Slider {...settings} arrows={false}>

                  {testimonial.map((item, i) => <Row gutter={16} key={i}>
                    <Col span={24}>
                      <div className="bg-secondary p-4 rounded-3" style={{ minHeight: 300 }}>
                        <div className="d-flex justify-content-start align-items-center"><Image src={item.photo?.url} className='rounded-circle' preview={false} width={70} height={70} />
                          <div>
                            <Title level={3} className='ms-3 mb-0 mt-2' >{item.fullName}</Title>
                            <Title level={4} className='text-success ms-3 mt-0' >{item.title}</Title>
                          </div>
                        </div>
                        <Rate className='flex-center py-1' disabled value={item.rating} />
                        <Paragraph ellipsis={{ rows: 4 }} className='fs-6 text-justify mt-3'>{item.description}</Paragraph>
                      </div>
                    </Col>
                  </Row>)}

                </Slider>
              ) : (<div className="spinner-border d-block mx-auto" style={{ width: "4rem", height: '4rem' }} ></div>)
            }


          </Col>
        </Row>

      </div>
    </div >
  )
}