import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import icon1 from '../../../assets/images/pour.png'
import icon2 from '../../../assets/images/nutrition (1).png'
import icon3 from '../../../assets/images/nutrition.png'

const { Title } = Typography;

export default function Services() {

  const data = [
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/service/1.jpg', icon: icon1, title: 'Prosonalized Nutrition', description: 'How all this mistaken idea of denouncing pleasure and prasing pain was bron', no: '01' },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/service/2.jpg', icon: icon2, title: 'Health Diet Programs', description: 'How all this mistaken idea of denouncing pleasure and prasing pain was bron', no: '02' },
    { url: 'https://peacefulqode.com/themes/nutritius/html/images/service/3.jpg', icon: icon3, title: 'Weight Loss Program', description: 'How all this mistaken idea of denouncing pleasure and prasing pain was bron', no: '03' },
  ]

  return (

    <div className='py-5'>
      <div className="container">

        <Title level={4} className='text-success text-center'>WHAT WE OFFER</Title>
        <Title level={1} className='text-center mt-0'>Our Diet &amp; Nutrition Services</Title>

        <Row gutter={[16, 26]} className='mt-5'>

          {data.map((item, i) => <Col xs={24} md={24} lg={8} key={i}>
            <div className="bg-secondary p-4 card-hover card-shahdow">
              <Title level={1} className='text-center'>{item.no}</Title>

              <div className='card-icon p-4 rounded-pill d-block mx-auto position-sticky' style={{ width: 85, height: 85, zIndex: 1, marginBottom: -25 }}>
                <Image src={item.icon} preview={false} alt="Icon" className='d-block mx-auto' width={35} height={35} />
              </div>

              <div className="flex-center"><Image src={item.url} preview={false} className='card-img img-fluid' /></div>

              <Title level={4} className='text-center mt-3'>{item.title}</Title>
              <p className='text-center mb-0 fs-6'>{item.description}</p>
            </div>
          </Col>)}

        </Row>

      </div>
    </div>

  )
}
