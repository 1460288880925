import React from 'react'
import About from './About'
import Hero from './Hero'
import MoreInfo from '../Home/MoreInfo'
import Expertise from '../Home/Expertise'
import Testimonial from '../Home/Testimonials'
import News from 'components/frontend/News'

export default function index() {
    return (
        <>
            <Hero />
            <MoreInfo />
            <About />
            <Testimonial />
            <Expertise />
            <News />
        </>
    )
}













