import React, { useState, useEffect, createContext, useContext, useReducer } from 'react'
import { auth, firestore } from 'config/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
const AuthContextProvider = createContext();

const initialValue = {
    isAuthenticated: false,
    user: {},
}

const reducer = ((state, { type, payload }) => {
    switch (type) {
        case "LOGIN":
            const { user, isSuperAdmin, isCustomer } = payload
            return { ...state, isAuthenticated: true, user, isSuperAdmin, isCustomer  }
        case "LOGOUT":
            return { isAuthenticated: false, }
        case "SET_PROFILE":
            return { ...state, user: payload.user }
        default:
            return state
    }
})

export default function AuthContext({ children }) {

    const [isAppLoading, setisAppLoading] = useState(false);
    const [states, dispatch] = useReducer(reducer, initialValue)
    const [user, setUser] = useState({});

    useEffect(() => {
        setisAppLoading(true)
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const docRef = doc(firestore, "users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let userData = docSnap.data();
                    const isSuperAdmin = userData.roles.includes("superAdmin")
                    const isCustomer = user.roles?.includes("customer")
                    dispatch({ type: "LOGIN", payload: { user: userData, isSuperAdmin, isCustomer } })
                    setUser(userData)
                } else {
                    console.log("No such document!");
                }
            } else {
                dispatch({ type: "LOGOUT" })
            }
            setisAppLoading(false)

        });
    }, [])

    const handlLogOut = () => {
        signOut(auth)
            .then(() => {
                dispatch({ type: "LOGOUT" })
                setUser({})
                window.toastify("user successfully Logout", "success")
            }).catch((error) => {
                window.toastify(error, "error")
            })
    }

    return (
        <AuthContextProvider.Provider value={{ ...states, isAppLoading, user, handlLogOut, dispatch }}>
            {children}
        </AuthContextProvider.Provider>
    )
}

export const useAuthGlobalContext = () => {
    return useContext(AuthContextProvider)
}