import React, { useState } from 'react'
import { Col, Row, Typography, Radio, Input } from 'antd';

const { Title, Text } = Typography

export default function FAQs() {

  const [value, setValue] = useState('Imperial');
  const [result, setResult] = useState(false);

  return (

    <div className="py-5 bg-primary">
      <div className='container'>

        <Row gutter={[16, 16]}>

          <Col md={24} xl={8} >
            <Title className='text-secondary' level={4}>CALCULATE YOUR BMI</Title>
            <Title className='text-white mt-1'>Calculate Body Mask Index</Title>
          </Col>

          <Col md={24} xl={16} >

            <Radio.Group size='large' onChange={(e) => setValue(e.target.value)} value={value} className='text-white'>
              <Radio className='text-white' value='Imperial'>Imperial</Radio>
              <Radio className='text-white' value='Metric'>Metric</Radio>
            </Radio.Group>

            <div className="mt-3">

              {value === 'Imperial' ? <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>

                  <Row gutter={8}>
                    <Col span={12}>
                      <Input placeholder='FT' name='FT' className='bg-dark text-white border-0 rounded-0 p-3 mb-2' />
                      <Text className='text-white fs-6'>Height</Text>
                    </Col>

                    <Col span={12}>
                      <Input placeholder='IN' name='IN' className='bg-dark text-white border-0 rounded-0 p-3' />
                    </Col>
                  </Row>

                </Col>

                <Col xs={24} md={8}>
                  <Input placeholder='LBS' name='LBS' className='bg-dark text-white border-0 rounded-0 p-3 mb-2' />
                  <Text className='text-white fs-6'>Weight</Text>
                </Col>

                <Col xs={24} md={8}>
                  <button className='border-0 w-100 text-white fs-6' onClick={() => setResult(true)} style={{ paddingBottom: 15, paddingTop: 15, background: '#7fb82d' }}>CALCULATE</button>
                </Col>

                {result &&
                  <Col span={24}>
                    <div className="flex-center py-4" style={{ background: '#dc3545' }}>
                      <Text className='text-white fs-6'>Your BMI is 0.01 <br /> (severe thinness)</Text>
                    </div>
                  </Col>}

              </Row>

                : <Row gutter={[16, 16]}>

                  <Col xs={24} md={8}>
                    <Input placeholder='CM' name='CM' className='bg-dark text-white border-0 rounded-0 p-3 mb-2' />
                    <Text className='text-white fs-6'>Height</Text>
                  </Col>

                  <Col xs={24} md={8}>
                    <Input placeholder='KG' name='KG' className='bg-dark text-white border-0 rounded-0 p-3 mb-2' />
                    <Text className='text-white fs-6'>Weight</Text>
                  </Col>

                  <Col xs={24} md={8}>
                    <button className='border-0 w-100 text-white fs-6' onClick={() => setResult(true)} style={{ paddingBottom: 15, paddingTop: 15, background: '#7fb82d' }}>CALCULATE</button>
                  </Col>

                  {result && <Col span={24}>
                    <div className="flex-center py-4" style={{ background: '#dc3545' }}>
                      <Text className='text-white fs-6'>Your BMI is 0.01 <br /> (severe thinness)</Text>
                    </div>
                  </Col>}
                </Row>}

            </div>
          </Col>
        </Row>

      </div>
    </div >

  )
}