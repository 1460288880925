import React from 'react'
import { Col, Image, Row, Typography } from 'antd'
import { FiStar } from 'react-icons/fi';
import { BiMedal } from 'react-icons/bi';
import { FaRegUser } from 'react-icons/fa';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const data = [
    { text: 'Balance Body & Mind' },
    { text: 'Personal Coaching' },
    { text: 'Personalized Nutrition' },
    { text: 'Sports Nutritionist' },
    { text: 'Child Nutrition' },
    { text: 'Support & Motivation' },
]

export default function MoreInfo() {

    const location = useLocation()
    const navigate = useNavigate()

    return (

        <div className='py-5' style={{ background: '#f8f8f8' }}>
            <div className="container home-about">

                <Row gutter={[24, 16]}>
                    <Col xs={24} lg={12}>
                        <Image src='https://peacefulqode.com/themes/nutritius/html/images/About-us/3.jpg' height='95%' width='100%' preview={false} />
                    </Col>

                    <Col xs={24} lg={12}>

                        <div className='px-3'>
                            <Title level={4} className='text-success'>ABOUT NUTRITIUS</Title>
                            <Title className='mt-0' level={2}>Improving By The Inspir Healthy Living</Title>
                            <Text className='fs-6'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</Text>

                            <Title level={3} className='mt-2'>Nutration Special Offers</Title>

                            <Row gutter={[8, 8]}>
                                {data.map((item, i) => <Col key={i} xs={24} md={12}><Text className='flex-start fs-6'><BsFillArrowRightCircleFill className='me-2 mb-1' color='#08220f' />{item.text}</Text></Col>
                                )}
                            </Row>

                            <Title level={5} className=' mt-4'><FiStar color='#08220f' className='me-2 mb-1 ' /> Best Nutrition Agency In Australia 2020</Title>
                            <Title level={5} className=''><BiMedal color='#08220f' className='me-2 mb-1 ' />Ranked Top 20 Agency Of The Decade</Title>
                            <Title level={5} className=''><FaRegUser color='#08220f' className='me-2 mb-1 ' />Honor Of Working With Fortune 400</Title>
                            {location.pathname === '/' && <button className='btn-dark mt-3' onClick={() => navigate('about')}>MORE ABOUT</button>}
                        </div>

                    </Col>

                </Row>

            </div>
        </div>
    )
}
