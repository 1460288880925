import React from 'react'
import Messages from './Messages'

export default function index() {
    return (
        <>
            <Messages />
        </>
    )
}
