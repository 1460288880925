import React, { useState } from 'react'
import { Button, Col, DatePicker, Divider, Form, Image, Input, Radio, Row, Select, Typography } from 'antd'
import { YoutubeOutlined, TwitterOutlined, InstagramOutlined, LinkedinOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { useDropzone } from 'react-dropzone';
import { doc, runTransaction, serverTimestamp, setDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { firestore, storage } from 'config/firebase';
import PhoneInput from 'react-phone-input-2'
import genders from 'data/genders.json'
import professions from 'data/Profession.json'
import religiouseducations from 'data/ReligiousEducation.json'
import modernEducations from 'data/ContemporaryEducation.json'
import skills from 'data/SkillSelect.json'
import Lottie from "lottie-react";
import success from 'data/success.json'
import { Country, State, City } from 'country-state-city';
// import { Link } from 'react-router-dom';
const countries = Country.getAllCountries();
const { Title, Text } = Typography
const { Option } = Select
const imageAccountsInfo = "https://res.cloudinary.com/techna/image/upload/v1677413131/accounts-info-new-min_hizpe2.jpg"

const initialState = {
    fullName: "",
    fatherName: "",
    dob: "",
    gender: null,
    profession: "",
    professionPlace: "",
    phone: "",
    whatsapp: "",
    email: "",
    educationReligious: null,
    educationModern: null,
    educationRelegiousInstitute: "",
    addtionalExpertise: "",
    country: null,
    region: null,
    city: "",
    address: "",
    skill: null,
    isFeeSubmitted: false
}

const socialMedia = [
    { title: <i className="fa-brands fa-facebook-f"></i>, url: "https://web.facebook.com/SkilledFuzala/" },
    { title: <YoutubeOutlined />, url: "https://www.youtube.com/skilledfuzala/" },
    { title: <TwitterOutlined />, url: "https://twitter.com/SkilledFuzala" },
    { title: <InstagramOutlined />, url: "https://www.instagram.com/skilledfuzala/" },
    { title: <LinkedinOutlined />, url: "https://www.linkedin.com/in/skilledfuzala/" },
    { title: <i className="fa-brands fa-tiktok"></i>, url: "https://www.tiktok.com/@skilledfuzala" },
]

const whatsappDefaultMsg = "السلامُ علیکم ورحمۃ اللہ۔ سر میں فارم مکمل طور پر فل کرچکا ہوں، اب آگے کا طریقہ کار کیا ہے؟ برائے کرم آگاہ فرمائیے۔"

export default function AddStudent() {

    const [state, setState] = useState(initialState)
    const [file, setFile] = useState(null)
    const [visible, setVisible] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const [regions, setRegions] = useState([])
    const [cities, setCities] = useState([])

    // Dropzone
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        multiple: false,
        onDrop: acceptedFiles => { setFile(acceptedFiles[0]) }
    });

    const handleChange = e => {
        const { name, value } = e.target
        setState(s => ({ ...s, [name]: value }))
    }

    const handleDOB = (dateObj, val) => {
        setState(s => ({ ...s, dob: val }))
    }
    const handleGender = (val) => {
        const gender = genders.find(gender => gender.value === val)
        setState(s => ({ ...s, gender }))
    }
    const handleProfession = (val) => {
        // const engagement = Profession.find(engagement.value === val)
        const profession = professions.find(profession => profession.value === val)
        setState(s => ({ ...s, profession }))
    }
    const handleEducationReligious = (val) => {
        const educationReligious = religiouseducations.find(educationReligious => educationReligious.value === val)
        setState(s => ({ ...s, educationReligious }))
    }
    const handleEducationModern = (val) => {
        const educationModern = modernEducations.find(education => education.value === val)
        setState(s => ({ ...s, educationModern }))
    }
    const handleSkill = (val) => {
        const skill = skills.find(skill => skill.value === val)
        setState(s => ({ ...s, skill }))
    }
    const handleCountry = (val) => {
        const country = countries.find(country => country.isoCode === val)
        // console.log("country =>", country)
        setState(s => ({ ...s, country, region: "", city: "" }))

        const regions = State.getStatesOfCountry(val);
        // console.log("regions =>", regions)
        setRegions(regions)
    }
    const handleRegion = (val) => {
        const region = regions.find(region => region.isoCode === val)
        // console.log("region =>", region)
        setState(s => ({ ...s, region, city: "" }))

        const cities = City.getCitiesOfState(state.country?.isoCode, val);
        // console.log("cities =>", cities)
        setCities(cities)
    }
    const handleCity = (val) => {
        const city = cities.find(city => city.name === val)
        // console.log("city =>", city)
        setState(s => ({ ...s, city }))
    }

    const handleSubmit = e => {
        e.preventDefault()

        let { fullName, fatherName, dob, gender, profession, professionPlace, phone, whatsapp, email, educationReligious, educationModern, educationRelegiousInstitute, addtionalExpertise, country, region, city, address, skill, isFeeSubmitted } = state

        fullName = fullName.trim()
        fatherName = fatherName.trim()
        professionPlace = professionPlace.trim()
        phone = phone.trim()
        whatsapp = whatsapp.trim()
        email = email.trim()
        educationRelegiousInstitute = educationRelegiousInstitute.trim()
        addtionalExpertise = addtionalExpertise.trim()
        address = address.trim()
        console.log(isFeeSubmitted)

        if (fullName.length < 3) { return window.toastify('براہ کرم اپنا پورا نام لکھیں', "error") }
        if (fatherName.length < 3) { return window.toastify('براہ کرم ولدیت لکھیں', "error") }
        if (!dob) { return window.toastify('براہ کرم تاریخ پیدائش سیلیکٹ کریں', "error") }
        if (!gender) { return window.toastify('براہ کرم جنس سیلیکٹ کریں', "error") }
        if (!profession) { return window.toastify('براہ کرم مصروفیت سیلیکٹ کریں', "error") }
        if (professionPlace.length < 3) { return window.toastify('براہ کرم مصروفیت کا مقام سیلیکٹ کریں', "error") }
        if (!phone) { return window.toastify('براہ کرم رابطہ نمبر', "error") }
        if (phone.slice(0, 1) === "0" || whatsapp.slice(0, 1) === "0") { return window.toastify("رابطہ نمر 0 سے شروع نہیں کر سکتے۔ براہ کرم 92 سے لکھنا شروع کریں", "error") }
        if (phone.slice(0, 2) === "92" && phone.length !== 12) { return window.toastify("براہ کرم رابطہ نمبر درست لکھیں", "error") } // Only for Pakistan
        if (!whatsapp) { return window.toastify('براہ کرم واٹس ایپ نمبر لکھیں', "error") }
        if (whatsapp.slice(0, 2) === "92" && whatsapp.length !== 12) { return window.toastify("براہ کرم واٹس ایپ نمبر درست لکھیں", "error") } // Only for Pakistan
        if (!educationReligious) { return window.toastify('براہ کرم دینی تعلیم سیلیکٹ کریں', "error") }
        if (!educationModern) { return window.toastify('براہ کرم عصری تعلیم سیلیکٹ کریں', "error") }
        if (educationRelegiousInstitute.length < 3) { return window.toastify('براہ کرم فراغت کس مدرسے سے ہے؟ یہ لکھیں', "error") }
        if (addtionalExpertise.length < 3) { return window.toastify('براہ کرم مزید کسی فیلڈ میں مہارت ہے تہ لکھیں۔ وگرنہ صرف نہیں لکھیں', "error") }
        if (!country) { return window.toastify('براہ کرم ملک سیلیکٹ کریں', "error") }
        if (!region) { return window.toastify('براہ کرم صوبہ سیلیکٹ کریں', "error") }
        if (!city) { return window.toastify('براہ کرم شہر سیلیکٹ کریں', "error") }
        if (address.length < 3) { return window.toastify('براہ کرم مستقل پتہ لکھیں', "error") }
        if (!skill) { return window.toastify('براہ کرم آپ کونسی سکل سیکھنا چاہتے ہیں سیلیکٹ کریں', "error") }
        if (file && file.size > 1000000) { return window.toastify('تصویر کا سائز 1 ایم بی سے کم ہونا چاہیے', "error") }
        if (!isFeeSubmitted) { return window.toastify('براہ کرم سیلیکٹ کریں۔ کیا آپ نے علامتی فیس ادا کردی ہے؟', "error") }

        let formData = {
            fullName, fatherName, dob, gender, profession, professionPlace, phone, whatsapp, email, educationReligious, educationModern, educationRelegiousInstitute, addtionalExpertise, country, region, city, address, skill, isFeeSubmitted,
            id: window.getRandomId(),
            status: "active",
            dateCreated: serverTimestamp(),
        }
        setIsProcessing(true)
        getRegistrationNumber(formData)
    }

    const getRegistrationNumber = async (formData) => {

        const docRef = doc(firestore, "transactions", "registration");

        try {
            await runTransaction(firestore, async (transaction) => {
                const document = await transaction.get(docRef);
                if (!document.exists()) {
                    console.error("Document does not exist!")
                }

                const number = document.data().number + 1;
                transaction.update(docRef, { number });

                formData.registrationNumber = number

                if (file) {
                    uploadFile(formData)
                } else {
                    createDocument(formData)
                }
            });
        } catch (e) {
            console.error("Transaction failed");
            window.toastify("Something went wrong while generating registration number", "error")
            setIsProcessing(false)
        }
    }

    const uploadFile = (formData) => {

        const ext = file.name.split('.').pop()
        const pathwithFileName = `admissionForms/${formData.id}/images/photo.${ext}`

        const fileRef = ref(storage, pathwithFileName);

        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on("state_changed", (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
            (error) => {
                console.error(error)
                window.toastify("Something went wrong while uploading photo.", "error")
                setIsProcessing(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let photo = { url: downloadURL, size: file.size }
                    createDocument({ ...formData, photo })
                });
            }
        )
    }

    const createDocument = async (formData) => {

        try {
            await setDoc(doc(firestore, "admissionForms", formData.id), formData)
            setIsFormSubmitted(true)
        } catch (e) {
            console.error(e)
            window.toastify("Something went wrong while submitting form", "error")
        }
        setIsProcessing(false)
    }

    if (isFormSubmitted)
        return (
            <main className='apply bg-primary p-3 p-md-4 p-lg-5 flex-center flex-column'>
                <Row className='mb-4'>
                    <Col span={24}>
                        <img src={window.logo} alt={window.appName + " logo"} className="img-fluid d-block mx-auto" />
                    </Col>
                </Row>
                <div className="card main w-100 mw-400px p-3 p-md-4 p-lg-5 flex-center">
                    <Lottie animationData={success} loop={1} width={200} height={200} />
                    <Title level={3} className="text-center text-primary text-urdu-mehr mt-4">آپ کا داخلہ فارم موصول ہو چکا ہے</Title>

                    <Row>
                        <Col span={24} className="mt-3">
                            <Divider orientation='center' className="text-urdu-mehr my-0">مزید معلومات کے لیے واٹس ایپ پر رابطہ فرمائیں</Divider>

                            <Button type='primary' className=" mt-3 flex-center w-100 text-white" style={{ backgroundColor: "#25d366" }} icon={<WhatsAppOutlined />}
                                onClick={() => window.open(`https://wa.me/923117355565?text=${whatsappDefaultMsg}`)}
                            >Chat on WhatsApp</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className="my-4">
                            <Divider orientation='center' className="text-urdu-mehr my-0">وقتاً فوقتاً اپڈیٹس کے لیے سوشل میڈیا اکاونٹس کو فالو کریں</Divider>
                        </Col>

                        <Col span={24} className="flex-center social-icons px-0">
                            {socialMedia.map((s, i) => {
                                return <a key={i} href={s.url} target="_blank" rel="noopener noreferrer" className='text-dark text-decoration-none border border-1 border-dark rounded-circle flex-center'>{s.title}</a>
                            })}
                        </Col>
                    </Row>
                </div>
            </main>
        )

    return (
        <>
            <div className="apply-banner flex-center">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">

                            <Title className="text-center text-secondary text-urdu-mehr" style={{ fontSize: "80px" }}>داخلہ فارم</Title>
                            <div className="divider my-1"></div>
                            <Text className="text-center text-white text-urdu-mehr fs-3">سٹار زدہ آپشنز لازمی ہیں<span className='text-secondary ms-2'>*</span></Text>

                            {/* <Title className='text-white fw-bold text-center'>ABOUT</Title>
                            <div className='flex-center buttons'>
                                <Link to="Palatform" smooth={true} duration={100}><Button className='bg-secondary rounded-pill border-0 text-dark fw-bold'>Palatform</Button></Link>
                                <Link to="CEO" smooth={true} duration={100}><Button className='bg-secondary rounded-pill border-0 text-dark fw-bold mx-0 my-3 mx-md-3 my-md-0'>CEO</Button></Link>
                                <Link to="Team" smooth={true} duration={100}><Button className='bg-secondary rounded-pill border-0 text-dark fw-bold'>Team</Button></Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <main className='apply py-5 p-3 p-md-4 p-lg-5 d-flex align-items-center'>
                <Row>
                    <Col xs={24} lg={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
                        {/* <Row className='mb-4'>
                        <Col span={24}>
                            <img src={window.logo} alt={window.appName + " logo"} className="img-fluid d-block mx-auto" />
                        </Col>
                    </Row> */}
                        {/* <Row className='mb-4'>
                            <Col span={24} className='text-center'>
                                <Title className="text-center text-secondary text-urdu-mehr" style={{ fontSize: "80px" }}>داخلہ فارم</Title>
                                <div className="divider my-1"></div>
                                <Text className="text-center text-white text-urdu-mehr fs-3">سٹار زدہ آپشنز لازمی ہیں<span className='text-secondary ms-2'>*</span></Text>
                            </Col>
                        </Row> */}
                        <div className="card main p-3 p-md-4">
                            <Form layout='vertical'>
                                <Row gutter={16} className="flex-row-reverse">
                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="نام" required>
                                            <Input placeholder="نام" name="fullName" onChange={handleChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="ولدیت	" required>
                                            <Input placeholder="ولدیت" name="fatherName" onChange={handleChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="تاریخ &ensp; پیدائش" required>
                                            <DatePicker className='w-100' onChange={handleDOB} placeholder="تاریخ &ensp; پیدائش"
                                                disabledDate={(current) => window.disabledDate(current, "future")}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="جنس" required>
                                            <Select
                                                showSearch
                                                placeholder="جنس"
                                                onChange={handleGender}
                                                options={genders}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="مصروفیت" required>
                                            <Select
                                                showSearch
                                                placeholder="مصروفیت"
                                                onChange={handleProfession}
                                                options={professions}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="مصروفیت کا مقام" required>
                                            <Input placeholder="مصروفیت کا مقام" name="professionPlace" onChange={handleChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="رابطہ نمبر" required>
                                            <PhoneInput
                                                country={'pk'}
                                                value={state.phone}
                                                onChange={phone => setState(s => ({ ...s, phone }))}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={8}>
                                        <Form.Item label="واٹس ایپ نمبر" required>
                                            <PhoneInput
                                                country={'pk'}
                                                value={state.whatsapp}
                                                onChange={whatsapp => setState(s => ({ ...s, whatsapp }))}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={8}>
                                        <Form.Item label="ای میل">
                                            <Input placeholder="ای میل" name="email" onChange={handleChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={6}>
                                        <Form.Item label="دینی تعلیم" required>
                                            <Select
                                                showSearch
                                                placeholder="دینی تعلیم"
                                                onChange={handleEducationReligious}
                                                options={religiouseducations}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={6}>
                                        <Form.Item label="عصری تعلیم" required>
                                            <Select
                                                showSearch
                                                placeholder="عصری تعلیم"
                                                onChange={handleEducationModern}
                                                options={modernEducations}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={6}>
                                        <Form.Item label="فراغت کس مدرسے سے ہے؟" required>
                                            <Input placeholder="فراغت کس مدرسے سے ہے؟" name="educationRelegiousInstitute" onChange={handleChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={6}>
                                        <Form.Item label="مزید کسی فیلڈ میں مہارت؟" required>
                                            <Input placeholder="مزید کسی فیلڈ میں مہارت؟" name="addtionalExpertise" onChange={handleChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={8}>
                                        <Form.Item label="ملک" required>
                                            <Select
                                                showSearch
                                                placeholder="ملک"
                                                onChange={handleCountry}
                                                filterOption={(input, option) =>
                                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                {countries.map((item, i) => <Option key={i} value={item.isoCode}>{item.name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={8}>
                                        <Form.Item label="صوبہ" required>
                                            <Select
                                                showSearch
                                                placeholder="صوبہ"
                                                onChange={handleRegion}
                                                filterOption={(input, option) =>
                                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                value={state.region?.isoCode}
                                                disabled={!state.country}
                                            >
                                                {regions.map((item, i) => <Option key={i} value={item.isoCode}>{item.name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <Form.Item label="شہر" required>
                                            <Select
                                                showSearch
                                                placeholder="شہر"
                                                onChange={handleCity}
                                                filterOption={(input, option) =>
                                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                value={state.city?.name}
                                                disabled={!state.country || !state.region}
                                            >
                                                {cities.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24}>
                                        <Form.Item label="مستقل پتہ" required>
                                            <Input placeholder="مستقل پتہ" name="address" onChange={handleChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={file ? 8 : 12}>
                                        <Form.Item label="آپ کونسی سکل سیکھنا چاہتے ہیں؟" required>
                                            <Select
                                                showSearch
                                                placeholder="سکل سلیکٹ کریں"
                                                onChange={handleSkill}
                                                options={skills}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={file ? 12 : 24} md={file ? 8 : 12}>
                                        <Form.Item label="تصویر اپلوڈ کریں">
                                            <div {...getRootProps({ className: 'dropzone py-0 px-1 justify-content-center', style: { height: 32, borderRadius: 6 } })}>
                                                <input {...getInputProps()} />
                                                <p className='m-0'>Click or drag file</p>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    {file &&
                                        <Col xs={12} md={8}>
                                            <Form.Item label="Preview" className='text-center'>
                                                <div className="image-preview"><img src={URL.createObjectURL(file)} alt="Student" className='img-fluid rounded-1' /></div>
                                            </Form.Item>
                                        </Col>
                                    }
                                </Row>
                                <Row gutter={16}>
                                    {/* <Col span={24} className="text-end">
                                        <Text className='text-danger text-urdu-mehr m-0'><b>نوٹ:&ensp;&ensp;</b>سکلڈ فضلاء کے اس سیمینار  کو سنجیدہ بنانے کے لیے 300 روپے علامتی فیس مقرر کی گئی ہے جسے ادا کرنا ضروری ہے اس کے بغیر رجسٹریشن نامکمل ہوگی۔</Text>
                                    </Col> */}
                                    <Col span={24} className="text-end d-flex flex-row-reverse mt-3">
                                        <div>
                                            <Text className='text-black text-urdu-mehr'>اکاؤنٹس کے لیے دیے گۓ بٹن پر کلک کریں۔</Text>
                                        </div>
                                        <div>
                                            <button className='btn btn-primary btn-sm text-urdu-mehr me-2' onClick={() => { setVisible(true) }}>اکاؤنٹس</button>
                                            {/* <Link to="/contact" className='btn btn-primary btn-sm text-urdu-mehr me-2'>اکاؤنٹس</Link> */}
                                        </div>
                                    </Col>

                                    <Col span={24} className="text-end my-3">
                                        <Radio.Group onChange={e => { setState(s => ({ ...s, isFeeSubmitted: e.target.value })) }} value={state.isFeeSubmitted}>
                                            <Radio value="no" className='text-urdu-mehr'>نہیں</Radio>
                                            <Radio value="yes" className='text-urdu-mehr'>ہاں</Radio>
                                        </Radio.Group>
                                        <label htmlFor="isFeeSubmitted" className="text-urdu-mehr">کیا آپ نے علامتی فیس ادا کردی ہے؟</label>
                                        {/* <Checkbox id='isFeeSubmitted' onChange={e => { setState(s => ({ ...s, isFeeSubmitted: e.target.checked })) }}></Checkbox> */}
                                    </Col>

                                    <Col xs={24} lg={{ span: 12, offset: 6 }} className='text-end'>
                                        <button className='btn btn-primary w-100 text-urdu-mehr' disabled onClick={handleSubmit}>
                                            {!isProcessing
                                                ? "داخلہ لیں"
                                                : <span className='spinner-grow spinner-grow-sm'></span>
                                            }
                                        </button>
                                    </Col>

                                    <Col span={24} className="mt-5 mb-4">
                                        <Divider orientation='center' className="text-urdu-mehr my-0">مزید معلومات کے لیے سوشل میڈیا اکاؤنٹس کو فالو کریں</Divider>
                                    </Col>
                                    <Col span={24} className="flex-center social-icons px-0">
                                        {socialMedia.map((s, i) => {
                                            return <a key={i} href={s.url} target="_blank" rel="noopener noreferrer" className='text-dark text-decoration-none border border-1 border-dark rounded-circle flex-center'>{s.title}</a>
                                        })}
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Image preview={{ visible, src: imageAccountsInfo, onVisibleChange: (value) => { setVisible(value) } }} />
            </main>
        </>
    )
}
