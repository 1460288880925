import React from 'react'
import { Divider, Space } from 'antd'
import { FaFacebookF, FaTwitter, FaLinkedinIn,FaWhatsapp } from 'react-icons/fa';
import { AiFillInstagram, AiOutlineMail, AiFillPhone, AiFillSkype } from 'react-icons/ai';

export default function Topbar() {

    const socialMediaLinks = [
        { url: "https://www.facebook.com/dieteticsrefillamna", icon: FaFacebookF },
        { url: "https://www.instagram.com/dt._amna_akmal/", icon: AiFillInstagram },
        { url: "https://www.linkedin.com/in/dieteticsrefill/", icon: FaLinkedinIn },
        { url: "https://wa.me/923136994019", icon: FaWhatsapp },
        { url: "#", icon: FaTwitter },
        { url: "#", icon: AiFillSkype },
    ]

    return (
        <header id='topbar' className='flex-center bg-primary'>
            <div className="container">

                <div className="d-flex justify-content-center justify-content-md-between align-items-center">

                    <div className='flex-center'>
                        <a href='mailto:info@dieteticsrefill.com' className='mb-1 me-2'><AiOutlineMail color='#ece4d9' size={19} /></a>
                        <a href='mailto:info@dieteticsrefill.com' className='text-decoration-none d-none d-md-block text-white'>info@dieteticsrefill.com</a>

                        <Divider type='vertical' className='mx-3 d-none d-md-block border-secondary' style={{ borderWidth: 2, height: "20px" }} />

                        <a href='tel:+9203136994019' className='me-2 mb-1'><AiFillPhone color='#ece4d9' size={19} /></a>
                        <a href="tel:+9203136994019" className='text-decoration-none text-white d-none d-md-block'>+92 313 699 40 19</a>
                    </div>

                    <div>
                        <Space className='mb-1'>
                            {socialMediaLinks.map((item, i) => {
                                return <a key={i} href={item.url} target="_blank" rel="noopener noreferrer"><item.icon color='#ece4d9' size={19} /></a>
                            })}
                        </Space>
                    </div>

                </div>

            </div >
        </header >
    )
}
