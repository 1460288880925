import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Dropdown, Input, Modal, Row, Table, Tag } from 'antd'
import { collection, doc, getDocs, limit, query, updateDoc, where } from 'firebase/firestore'
import { firestore } from 'config/firebase'
import { MoreOutlined } from "@ant-design/icons";
// import emailjs from '@emailjs/browser';  
const { Search } = Input;

export default function Hero() {

    const [users, setUsers] = useState({});
    // const [subcribers, setSubcribers] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [documents, setDocuments] = useState([])
    const [filteredDocuments, setFilteredDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)

    const handleSearch = value => {
        setSearchText(value);
    };

    const readDocuments = useCallback(async () => {
        try {
            const collectionRef = query(collection(firestore, "users"), where("status", "==", "active"))
            const querySnapshot = await getDocs(collectionRef);

            let array = [];
            querySnapshot.forEach((docs) => {
                let data = docs.data()
                array.push(data)
            })
            setUsers(array)

        } catch (error) {
            window.toastify("Something went wrong", "error")
        }
    }, [])

    useEffect(() => {
        readDocuments()
    }, [readDocuments])

    const readDocs = useCallback(async () => {

        const collectionRef = collection(firestore, "subscribers");
        const statusActive = where("status", "==", "active");
        const q = query(collectionRef, statusActive, limit(pageSize));

        setIsLoading(true);
        const querySnapshot = await getDocs(q);
        const array = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const dateCreated = doc.data().dateCreated;
            let date = null;
            if (dateCreated && dateCreated.seconds) {
                date = new Date(dateCreated.seconds * 1000 + dateCreated.nanoseconds / 1000000);
                data.date = date.toLocaleString();
            }
            data.key = doc.id;
            array.push(data);
        });
        array.sort((a, b) => b.dateCreated.seconds - a.dateCreated.seconds);
        setDocuments(array);
        setFilteredDocuments(array);
        setIsLoading(false);

    }, [pageSize]);

    useEffect(() => {
        readDocs();
    }, [readDocs]);

    useEffect(() => {
        const start = (currentPage - 1) * pageSize;
        const end = start + pageSize;
        setFilteredDocuments(documents.slice(start, end));
    }, [currentPage, pageSize, documents]);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    useEffect(() => {
        const filteredData = documents?.filter((item) =>
            item.email.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredDocuments(filteredData);
    }, [documents, searchText]);


    const handleDelete = async (id) => {

        setIsLoading(true)
        try {
            await updateDoc(doc(firestore, "subscribers", id), { status: "deleted" })
            let activeDocuments = documents.filter(item => item.id !== id);
            setDocuments(activeDocuments)
            setIsModalVisible(false)
            setIsLoading(false)
            window.toastify("Subscriber successfully deleted", "success")
        } catch (error) {
            window.toastify("Something went wrong. Please try again.", "error")
        }
        setIsLoading(false)

    }

    const sendEmail = (record) => {
        // e.preventDefault()

        let data = { ...record }
        delete data.id
        // emailjs.send(process.env.REACT_APP_SERVICE_EMAIL_KEY, process.env.REACT_APP_TEMPLATE_EMAIL_ID, data, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
        //     .then((result) => {
        //         window.toastify("Email has been successfullfy sent", "success")
        //     }, (error) => {
        //         console.error("error =>", error.text);
        //         window.toastify("Something went wrong. Please try again", "error")
        //     });

    };

    const columns = [

        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (tags) => (<> <Tag color="green" className='text-capitalize' >  {tags} </Tag> </>)
        },
        {
            title: 'Action',
            render: (_, row, index) => {
                return (
                    <>
                        <Dropdown
                            menu={{
                                items: [
                                    { label: "Send Email", onClick: () => { sendEmail(row) } },
                                    { label: "Delete", onClick: () => { setIsModalVisible(index) } },
                                ]
                            }}
                            trigger={['click']}
                        >
                            <MoreOutlined className='text-primary' />
                        </Dropdown>
                        <Modal
                            title="Confirm Delete"
                            open={isModalVisible === index}
                            confirmLoading={isLoading}
                            onOk={() => handleDelete(row.id)}
                            okButtonProps={{ style: { backgroundColor: '#ff4d4f', color: "white" } }}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <p>Are you sure you want to delete this item?</p>
                        </Modal>
                    </>
                )
            }
        },
    ];


    return (
        <div className='content-min-vh dashboard'>
            <div className='mb-5'>
                <h5>Welcome Admin!</h5>
            </div>
            <div>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={8} >
                        <Card title="Total User" className='mb-3 cardBorder'>
                            <p className='fw-bold text-center fs-5'>{users.length}</p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} >
                        <Card title="Total Blogs" className='mb-3 cardBorder'>
                            <p className='fw-bold text-center fs-5'>0</p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Card title="Total Subcriber" className='mb-3 cardBorder'>
                            <p className='fw-bold text-center fs-5'>{documents.length}</p>
                        </Card>
                    </Col>
                </Row>
            </div>

            <div>
                <Row>
                    <Col xs={24} >
                        <div className="card bg-white p-4">
                            <div className='py-4'>
                                <div className='d-flex justify-content-between '>
                                    <p className='fw-bold pt-1 me-3'>Recently Subcribers</p>
                                    <div>
                                        <Search placeholder='Search'
                                            onChange={e => handleSearch(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Table loading={isLoading}
                                dataSource={filteredDocuments}
                                columns={columns}
                                pagination={{ current: currentPage, pageSize: pageSize, total: documents.length, showSizeChanger: true, }}
                                onChange={handleTableChange}
                                scroll={{ x: true }}
                                bordered
                            />
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}
