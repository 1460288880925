import React, { useCallback, useState, useEffect } from 'react';
import { Breadcrumb, Button, Col, Divider, Dropdown, Modal, Rate, Row, Space, Table, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import { MoreOutlined } from "@ant-design/icons"
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { firestore } from 'config/firebase';

const { Title, Text } = Typography;

export default function AllTestimonial() {

    const [testimonial, setTestimonial] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false)
    let navigate = useNavigate()

    const readTestimonial = useCallback(async () => {
        setIsLoading(true)
        const collectionRef = query(collection(firestore, "testimonial"), where("status", "==", "active"))
        const querySnapshot = await getDocs(collectionRef);

        let array = [];
        querySnapshot.forEach((docs) => {
            let data = docs.data()
            data.key = data.id
            array.push(data)
        })
        setTestimonial(array)
        setIsLoading(false)

    }, [])

    useEffect(() => {
        readTestimonial()
    }, [readTestimonial])

    const handleDelete = async (id) => {

        setIsLoading(true)
        try {
            await updateDoc(doc(firestore, "testimonial", id), { status: "deleted" })
            let activeTestimonial = testimonial.filter(item => item.id !== id);
            setTestimonial(activeTestimonial)
            setIsModalVisible(false)
            setIsLoading(false)
            window.toastify("Testimonial successfully deleted", "success")
        } catch (error) {
            window.toastify("Something went wrong. Please try again.", "error")
        }
        setIsLoading(false)

    }

    const columns = [
        {
            title: 'Image',
            render: (item, row) => { return (<img src={row.photo.url} alt="logo" className='avatar' />) }
        },
        {
            title: 'Rating',
            render: (item, row) => { return <> <Rate className='fs-4' disabled value={row.rating} /></> }
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            sorter: (a, b) => a.firstName.length - b.firstName.length,
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
        },
        {
            title: 'Sort Order',
            dataIndex: 'sortOrder',
        },

        {
            title: 'Action',
            render: (_, row, index) => {
                return (
                    <>
                        <Dropdown
                            menu={{
                                items: [
                                    { label: "Edit", onClick: () => { navigate(row.id) } },
                                    { label: "Delete", onClick: () => { setIsModalVisible(index) } },
                                ]
                            }}
                            trigger={['click']}
                        >
                            <MoreOutlined className='text-primary' />
                        </Dropdown>
                        <Modal
                            title="Confirm Delete"
                            open={isModalVisible === index}
                            confirmLoading={isLoading}
                            onOk={() => handleDelete(row.id)}
                            okButtonProps={{ style: { backgroundColor: '#ff4d4f', color: "white" } }}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <p>Are you sure you want to delete this item?</p>
                        </Modal>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Testimonials</Title>
                </Col>
            </Row>
            <div className='mb-4'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link className='text-decoration-none' to="/dashboard">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Testimonials</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row>
                <Col span={24}>
                    <div className="card p-4">
                        <Row>
                            <Col span={24} className="d-flex justify-content-end align-items-center">
                                <Space size="small">
                                    <Button type='primary' onClick={() => navigate("add")}>Add</Button>
                                </Space>
                            </Col>
                        </Row>

                        <Divider />

                        <Table columns={columns} dataSource={testimonial} scroll={{ x: true }} bordered loading={isLoading}
                            expandable={{
                                expandedRowRender: (row) => <div className='mx-5'>
                                    <Title level={5}>Review</Title>
                                    <Text>{row.description}</Text>
                                </div>,
                            }}
                        />
                    </div>
                </Col>
            </Row>

        </>
    )
}
