import React from 'react'
import img1 from '../../../assets/images/1-6.jpg'
import img2 from '../../../assets/images/2-6.jpg'
import img3 from '../../../assets/images/3-5.jpg'
import { useNavigate } from 'react-router-dom'

const carousel = [
  { img: img1, class: 'carousel-item active', title: `Healthy Choices For With  Healtheir Food You` },
  { img: img2, class: 'carousel-item', title: `Don't Give Up on Your Favourite Healtheir` },
  { img: img3, class: 'carousel-item', title: `Give your Body The Prope  Nutrrients Counts` },
]

export default function Hero() {
  
  const navigate = useNavigate()

  return (
    <>
    
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          {carousel.map((item, i) => {
            return (
              <div key={i} className={`${item.class}`}>
                <img src={item.img} className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                  <h5 className='border-left border-secondary text-secondary'>WELCOME THE SMART NUTRITIUS</h5>
                  <h1>{item.title}</h1>
                  <button className='btn-secondary mt-2' onClick={()=>navigate('about')}>READ MORE</button>
                  <button className='btn-dark ms-3 mt-2' onClick={()=>navigate('contact')}>CONTACT US</button>
                </div>
              </div>
            )
          })}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

    </>
  )
}
