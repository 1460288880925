import React from 'react'
import { Col, Image, Row, Typography } from 'antd'

const { Title, Text } = Typography;

const data = [
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/3.jpg", title: 'Physical Activity' },
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/4.jpg", title: 'Childern diet' },
    { url: "https://peacefulqode.com/themes/nutritius/html/images/protfolio/1.jpg", title: 'Weight losses sessions' },
]

export default function About() {

    return (
        <div className="py-5">
            <Row style={{ minHeight: '80vh' }}>
                <Col xs={24} lg={8} className='bg-secondary flex-center'>
                    <div className="p-4">
                        <Title level={5} className='text-success'>EXPLORE OUR PROJECTS</Title>
                        <Title className='mt-0' level={1}>Our Feature Projects</Title>
                        <Text className='fs-5 d-block'>There are many variations of passages of Lorem available, but the majority have suffered</Text>
                        <button className='btn-secondary mt-4' style={{ background: '#7fb82d', color: "white" }} >READ MORE</button>
                    </div>

                </Col>
                <Col xs={24} lg={16} style={{ background: '#7fb82d' }} className='flex-center'>
                    <Row gutter={[0,50]} className='py-5'>

                        {data.map((item, i) => <Col md={24} lg={8} key={i} >
                            <div className="mx-3">
                                <Image src={item.url} preview={false} width='100%' height='100%' />
                                <div className="bg-secondary p-3">
                                    <Title level={5} className='text-success'>DIETS</Title>
                                    <Title className='mt-0' level={4}>{item.title}</Title>
                                </div>
                            </div>
                        </Col>)}
                    </Row>

                </Col>
            </Row>
        </div>
    )
}
