import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainAd from './MainAd/MainAd'
import AddOurTeam from './Team/Add'
import AllOurTeam from './Team/All'
import EditOurTeam from './Team/Edit'
// import AddNewAlbum from './Album/Add'
// import AllAlbums from './Album/All'
// import Details from './Album/Edit'
// import AddGallery from './Album/Edit/Add'
// import EditGalllery from './Album/Edit/Edit'
// import AddFAQs from './Faqs/Add'
// import AllFAQs from './Faqs/All'
// import EditFAQs from './Faqs/Edit'
// import MainAd from './MainAd/MainAd'
import AddTestimonial from './Testimonials/Add'
import AllTestimonial from './Testimonials/All'
import EditTestimonial from './Testimonials/Edit'
// import AddVideo from './Videos/Add'
// import AllVideo from './Videos/All'
// import EditVideo from './Videos/Edit'

export default function index() {
    return (
        <Routes>
            {/* <Route path='albums/add' element={<AddNewAlbum />} />
            <Route path='albums' element={<AllAlbums />} />
            <Route path='albums/:id' element={<Details />} />
            <Route path='albums/:id/add' element={<AddGallery />} />
            <Route path='albums/:id/edit' element={<EditGalllery />} />
            <Route path='faqs' element={<AllFAQs />} />
            <Route path='faqs/add' element={<AddFAQs />} />
            <Route path='faqs/:id' element={<EditFAQs />} />
            <Route path='mainAd' element={<MainAd />} /> */}
            <Route path='ourteams/add' element={<AddOurTeam />} />
            <Route path='ourteams' element={<AllOurTeam />} />
            <Route path='ourteams/:id' element={<EditOurTeam />} />
            <Route path='mainAd' element={<MainAd />} />
            <Route path='testimonials' element={<AllTestimonial />} />
            <Route path='testimonials/add' element={<AddTestimonial />} />
            <Route path='testimonials/:id' element={<EditTestimonial />} />
            {/* <Route path='videos/add' element={<AddVideo />} />
            <Route path='videos' element={<AllVideo />} />
            <Route path='videos/:id' element={<EditVideo />} /> */}
        </Routes>
    )
}
