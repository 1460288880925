import React from 'react'
import { Col, Row, Typography } from 'antd'
import { AiFillHome } from 'react-icons/ai';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

export default function Hero() {
  
  const navigate = useNavigate();

  return (
    <div className="contact-hero d-flex align-items-center">
      <div className="container">
        <Row>
          <Col span={24}>
            <Title className='text-primary mb-0'>CONTACT US</Title>
            <div className="d-flex align-items-center">
              <Title level={5} className='text-primary mt-3 d-flex align-items-center cursor-pointer' onClick={()=>navigate('/')} ><AiFillHome color='#08220f' className='me-2' size={18} /> Home </Title>
              <Title level={5} className='text-success ms-2 mt-3 d-flex align-items-center cursor-pointer'><MdOutlineKeyboardArrowRight color='#08220f' className='me-2' size={20} /> Contact </Title>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
