import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumb, Button, Col, Form, Input, Row, Select, Tooltip, Typography } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
// import { useAuthGlobalContext } from 'context/AuthContext'
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { EditOutlined, CloseOutlined, InboxOutlined } from "@ant-design/icons"

const initialValue = { name: "", category: "", sortOrder: 1, description: "" }
const { Title } = Typography;

export default function EditProduct() {

    // const { user } = useAuthGlobalContext();
    const [state, setState] = useState(initialValue);
    const [newFiles, setNewFiles] = useState([]);
    const [isSlugEditable, setIsSlugEditable] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    // Dropzone
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        multiple: true,
        onDrop: acceptedfiles => {
            const updatedFiles = acceptedfiles.map((file) => {
                return { id: window.getRandomId(), file: file };
            });
            setNewFiles([...newFiles, ...updatedFiles])
        }
    });

    const readProduct = useCallback(async () => {

        const docRef = doc(firestore, "products", params.id)
        setIsProcessing(true)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setState(data)
        } else {
            window.toastify("Product not found", "errro")
        }
        setIsProcessing(false)

    }, [params.id])

    useEffect(() => {
        readProduct()
    }, [readProduct])

    const handleChange = e => {
        const { name, value } = e.target
        setState(s => ({ ...s, [name]: value }))

        if (name === "name") {
            let slug = window.getSlug(value)
            setState(s => ({ ...s, slug }))
        }

        if (name === "price" || name === "discount" || name === "charges") {
            setState(s => {
                let price = parseFloat(s.price);
                let discount = parseFloat(s.discount);
                let charges = parseFloat(s.charges);
                let discountAmount = price * (discount / 100)
                let netPrice = Math.round(price - discountAmount + charges);
                return { ...s, netPrice };
            });
        }

    }

    const handleProductStatus = (val) => {
        setState(s => ({ ...s, productStatus: val }))
    }

    const handleUpdate = () => {

        let { name, slug, category, stock, price, discount, charges, netPrice, productStatus, sortOrder, description } = state;

        if (name.length < 3) { return window.toastify("Please enter product name", "error") }
        if (!slug) { return window.toastify("Please enter slug", "error") }
        if (category.length < 3) { return window.toastify("Please enter category", "error") }
        if (!price) { return window.toastify("Please enter product price", "error") }
        if (!discount) { return window.toastify("Please enter product discount", "error") }
        if (!charges) { return window.toastify("Please enter product charges", "error") }
        if (!productStatus) { return window.toastify("Please enter product status", "error") }
        if (!description) { return window.toastify("Please enter product description", "error") }

        name = name.trim()
        slug = slug.trim()
        category = category.trim()
        stock = Number(stock)
        price = Number(price)
        discount = Number(discount)
        charges = Number(charges)
        netPrice = Number(netPrice)
        sortOrder = Number(sortOrder)
        description = description.trim()

        let formData = {
            ...state,
            name, slug, category, stock, price, discount, charges, netPrice, productStatus, sortOrder, description,
            dateModified: serverTimestamp(),
            status: "active",
        }
        // formData.modifiedBy = {
        //     fullName: user.name,
        //     email: user.email,
        //     uid: user.uid
        // }
        setIsProcessing(true)
        if (newFiles && newFiles.length > 0) {
            uploadFile(formData);
        } else {
            updateDocument(formData);
        }
    }

    const uploadFile = (formData) => {

        let fileDataArray = [];
        for (let i = 0; i < newFiles?.length; i++) {
            const fileData = newFiles[i];
            const randomId = window.getRandomId();
            const ext = fileData.file.name.split('.').pop()
            const pathwithFileName = `${randomId}/images/photo${i}.${ext}`

            const fileRef = ref(storage, pathwithFileName);

            const uploadTask = uploadBytesResumable(fileRef, fileData.file);
            uploadTask.on("state_changed", (snapshot) => {
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
                (error) => {
                    console.error(error)
                    window.toastify("Something went wrong while uploading photo.", "error")
                    setIsProcessing(false)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                        fileDataArray.push({ url: downloadURL, id: randomId, size: fileData.file.size, path: fileData.file.path })
                        if (fileDataArray.length === newFiles.length) {
                            const newData = { ...formData, photo: [...state.photo, ...fileDataArray] }
                            updateDocument(newData)
                        }

                    });
                }
            )
        }
    }

    const updateDocument = async (formData) => {

        const docRef = doc(firestore, "products", state.id)
        try {
            await setDoc(docRef, formData, { merge: true })
            setNewFiles(null)
            setIsProcessing(false)
            navigate("/dashboard/products")
            window.toastify("A product has been successfullfy updated", "success")
        } catch (error) {
            console.log(error)
            window.toastify("Something went wrong. Please try again", "error")
        }
    }

    const handleDelete = (id) => {
        let filterFile = state.photo.filter((item) => item.id !== id)
        setState(s => ({ ...s, photo: filterFile }));
    };

    const removeFile = (id) => {
        const newfiles = [...newFiles];
        let filterFile = newfiles.filter((item) => item.id !== id)
        setNewFiles(filterFile);
    };

    return (
        <div className='content-min-vh'>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Edit</Title>
                </Col>
            </Row>
            <div className='mb-3'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/dashboard" className='text-decoration-none'>Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Edit Product</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <Row gutter={16} >
                <Col xs={24} xl={16} className="mb-4">
                    <div className="card p-4">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Edit Product</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Product name" required>
                                        <Input placeholder='Product Name' name='name' value={state.name} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Slug" required>
                                        <Input.Group compact className='d-flex'>
                                            <Input placeholder="Slug" name="slug" disabled={isSlugEditable} value={state.slug} onChange={handleChange} style={{ width: "calc(100% - 32px)" }} />
                                            <Tooltip title={isSlugEditable ? "Edit" : "Cancel Edit"}>
                                                <Button onClick={() => { setIsSlugEditable(!isSlugEditable) }} icon={isSlugEditable ? <EditOutlined /> : <CloseOutlined />} />
                                            </Tooltip>
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Category" required>
                                        <Input placeholder='Category' name='category' value={state.category} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Stock" required>
                                        <Input type='number' placeholder="Stock" name="stock" value={state.stock} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Price" required>
                                        <Input type='number' placeholder="Price" name="price" value={state.price} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Discount %" required>
                                        <Input type='number' placeholder="10%" disabled={!state.price} value={state.discount} name="discount" onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Delivery Charge" required>
                                        <Input type='number' placeholder="Delivery Charge" disabled={!state.discount} value={state.charges} name="charges" onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Net Price" required>
                                        <Input type='number' placeholder="Net Price" name="netPrice" disabled value={state.netPrice} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Status" required>
                                        <Select
                                            showSearch
                                            className='w-100%'
                                            placeholder="Select to status"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            value={state.productStatus}
                                            onChange={(val) => handleProductStatus(val)}
                                            options={[
                                                { value: 'active', label: 'Active', },
                                                { value: 'inactive', label: 'In Active', },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={24}>
                                    <Form.Item label="Description">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Description" value={state.description} name="description" maxLength={200} showCount onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <Form layout="vertical">
                            <Form.Item label="Picture (1000*1000) px" className='mb-0 text-center'>
                                <>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <InboxOutlined />
                                        <input name='photo' {...getInputProps()} />
                                        <p className='mb-0'>Drop file here or click to upload.</p>
                                    </div>
                                </>
                            </Form.Item>
                        </Form>
                    </div>
                    <div>
                        {state.photo?.map((item, index) => {
                            let sizeInKB = Math.round(item.size / 1024);
                            return (
                                <Col key={item.id} xs={24} className='p-0'>
                                    <div className='card shadow-lg p-2 border mb-2'>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className='d-flex'>
                                                <img src={item?.url} className='img-fluid rounded-1 avatar-sm me-2' alt="img" />
                                                <div>
                                                    <p className='fw-bold opacity-75 p-0 m-0'>{item.path}</p>
                                                    <p className='p-0'>{sizeInKB} KB</p>
                                                </div>
                                            </div>
                                            <div>
                                                <button className='btn ' onClick={() => handleDelete(item.id)}><CloseOutlined /></button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </div>
                    <div>
                        {newFiles &&
                            newFiles?.map((files, index) => {
                                let sizeInKB = Math.round(files.file.size / 1024);
                                return (
                                    <Col key={files.id} xs={24} className='p-0'>
                                        <div className='card shadow-lg p-2 border mb-2'>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='d-flex'>
                                                    <img src={URL.createObjectURL(files.file)} className='img-fluid rounded-1 avatar-sm me-2' alt="img" />
                                                    <div>
                                                        <p className='fw-bold opacity-75 p-0 m-0'>{files.file.path}</p>
                                                        <p className='p-0'>{sizeInKB} KB</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn ' onClick={() => removeFile(files.id)}><CloseOutlined /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                    </div>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleUpdate}>
                            {!isProcessing
                                ? "Update"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>

            </Row>
        </div>
    )
}
